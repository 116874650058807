<template>
    <v-card style="width: 70vw!important; height: 80vh!important;">
        <v-toolbar color="blue" style="width:100%;">
            <span style="width:95%">Escolha o arquivo com o verso do documento</span>
            <span style="width:5%"> 
                <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
                    <v-icon x-small style="color:white;">mdi-close</v-icon>
                </v-btn>
            </span>
        </v-toolbar>  
        <div class="captura_container" >
            <div class="captura_container_doc">
                <h1>FOTO VERSO - {{tipoDocSelecionado}}</h1>
                <p v-if="!image" >modelo</p>
                <img v-if="tipoDocSelecionado == 'RG'" class="imgExemplo" src="/img/leitorweb/rg_foto_verso.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'OUTROS'" class="imgExemplo" src="/img/leitorweb/rg_foto_verso.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'CNH'" class="imgExemplo" src="/img/leitorweb/cnh_foto_frente.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'PASSAPORTE'" class="imgExemplo" src="/img/leitorweb/passaporte_foto_frente.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'DNI'" class="imgExemplo" src="/img/leitorweb/dni_foto_verso.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'RNE'" class="imgExemplo" src="/img/leitorweb/rne_foto_verso.jpg" style="max-width:50vw;" >

                <label  v-if="!image" for="file-upload" style="margin-top:10px;background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;">
                    <span style="font-size:18px;font-weight:bold;color:white;">Clique Aqui Para Enviar</span>
                </label>    
                <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="onFileChange" />
            

                <img v-if="image" class="imgUpload" :src="image" style="max-width:50vw;" >
                <button v-if="image" @click="removeImage" type="button" class="botaoRemover">Remover imagem</button>

            </div>
        </div>
    </v-card>
</template>

<script>
import {serializarImagemNew} from '../../../utils/serializar'

export default {
  data: () => ({
    image: ''
  }),
  props:["tipoDocSelecionado","idHospede","idReserva","idReservaHospede","docverso"],
  methods: {
    setarPasso(valor){
        this.$emit('setarPasso',valor)
    },
    async manipularDirecionamento(idImagem){
        console.log("DOC TIPO:"+this.tipoDocSelecionado)
        // se for DNI ou RNE irá fazer leitura OCR da frente
        if(this.tipoDocSelecionado == "DNI"  
            || this.tipoDocSelecionado == "RNE"
        ){
                
                //solicitar leitura OCR aqui
                /// -->
                console.log("Enviado para leitura OCR")
                await this.$http.post('ocr/lerDocAuthSimples',{
                    token:'3c8478c895580214e0ff389448054854',
                    tipo_doc:this.tipoDocSelecionado,
                    lado:'VERSO',
                    id:idImagem,
                    idHospede:this.idHospede,
                    idReserva:this.idReserva,
                    idReservaHospede:this.idReservaHospede,
                })
                .then(resp =>{
                    if(resp.status == 200){
                        console.log("Leitura OCR step fd")
                    }
                    
                    this.$emit('avancar')
                    this.setarPasso(0)
                    
                })



                // Finaliza
                this.$emit('avancar')
                this.setarPasso(0)

        }else{
            if(this.tipoDocSelecionado == "OUTROS"){
                this.setarPasso(8)
            }else{
                // se NÃO for DNI ou RNE então só finaliza
                this.$emit('avancar')
                this.setarPasso(0)
            }
        }
    },
    async setImage(e) {
      const file = e.target.files[0];

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.isImg = true;
          this.imgSrc = event.target.result;
          let index = event.target.result.indexOf(",") + 1;
          this.imgSrc = event.target.result.substring(index,event.target.result.length);
          if(this.imgSrc){
              this.salvaLogo(file.type)
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    async salvaLogo(tipoImg) {
        if (!this.imgSrc.length > 0) {
            console.log("invalido");
            return;
        }

        let dados =   {
            "token":'3c8478c895580214e0ff389448054854',
            "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
            "tipo" : tipoImg,
            "nome" : `DocumentoVerso_${this.idHospede}`,
            "dados" : this.imgSrc,
            "tabela": "hospede",
            "fk": "docverso",
            "chave": "id",
            "valor": this.idHospede
        }
        await this.$http.post("/imagem/upinsertAuthSimples",dados,
            {timeout:60000 }
            )
            .then((response) => {
                if(response.status == 200){
                    console.log("Documento Verso Salvo!")
                    this.manipularDirecionamento(response.data.result.imagem[0])
                    this.$emit('update')
                }else{
                    console.log("Não foi possivel salvar Documento Verso!")
                }
            })
            .catch((error) => {
            console.log(error.response);
            });
    },
    onFileChange(e) {

        // this.setImage(e)

        // serializarImagem(e, 0.7).then(resp =>{
        //     this.imgSrc = resp.dados
        //     this.salvaLogo(resp.tipo);
        // }).catch(error =>{
        //     this.loading = false
        //     this.snack = true
        //     this.snackColor = "error" 
        //     this.snackText = error.message
        // })
        serializarImagemNew(e).then(resp =>{
            this.imgSrc = resp.dados
            this.salvaLogo(resp.tipo);
        }).catch(error =>{
            this.loading = false
            this.snack = true
            this.snackColor = "error" 
            this.snackText = error.message
        })
    },
    createImage(file) {
        
        this.image = new Image();
        var reader = new FileReader();
        var vm = this;
       
        reader.onload = (e) => {
    
            //RESIZE
            var canvas_frente = document.createElement("canvas");
            var context_frente = canvas_frente.getContext("2d");
            const image_doc_frente = new Image();
            image_doc_frente.onload = () =>{

                    //se for deitado
                    if(image_doc_frente.width > image_doc_frente.height){
                        // canvas_frente.width = 1000;
                        // canvas_frente.height = 600;
                        canvas_frente.width = 1000;
                        canvas_frente.height = 600;
                        context_frente.drawImage(image_doc_frente, 0, 0, 1000, 600);
                    }else{
                        //se for em pe
                        // canvas_frente.width = 800;
                        // canvas_frente.height = 1000;

                        canvas_frente.width = 700;
                        canvas_frente.height = 800;
                        context_frente.drawImage(image_doc_frente, 0, 0, 700, 800);
                    }
                    

                    // this.realizarUpload(canvas_frente,this.$store.getters.get_id_web_checkin)
                    this.$emit('avancar')
                    this.setarPasso(0)
                    
            }
            image_doc_frente.src = e.target.result;
            //RESIZE
            vm.image = e.target.result;

        };
        reader.readAsDataURL(file);
    },
    removeImage: function () {
        this.image = '';
    }
  },
  mounted() {
    this.$store.dispatch("setHiddenDrawer", true)
  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:red; */
        /* width:100vw; */
         height: 55vh; 
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        /* width:80vw; */
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>
<template>
        <div class="captura_container" >

            <div style="padding-top:10px;width:100%;padding-right:5%;padding-left:5%;display:flex;justify-content:space-between;position:fixed;top:0px;">
                  <div v-if="tagSincronizada" style="color:white;font-size:18px;background-color:#2c9700;padding:10px;">TAG SINC.: {{tagSincronizada}}</div>
                  <div v-else style="color:white;font-size:18px;background-color:#009797;padding:10px;">QR CODE: {{apelido}}</div>
                  <v-btn large color="error" @click="logout()">Sair do Sistema</v-btn>
            </div>

            <div class="captura_container_tipos">
                <a @click="setarPasso(2,'RG')" class="tipos" >RG</a>
                <a @click="setarPasso(2,'PASSAPORTE')" class="tipos" >PASSAPORTE</a>
                <a @click="setarPasso(2,'CNH')" class="tipos" >CNH</a>
                <!-- <a @click="lerQRCODE()" class="tipos" >CNH QR CODE</a> -->
                <a @click="setarPasso(2,'DNI')" class="tipos" >DNI</a>
                <a @click="setarPasso(2,'RNE')" class="tipos" >RNE</a>
                <a @click="setarPasso(2,'OUTROS')" class="tipos" >OUTROS</a>
            </div>
        </div>
</template>

<script>
import QRScanner from 'qr-code-scanner';
export default {
  data: () => ({
    image: '',
    tagSincronizada:null,
    apelido:null,
  }),
  methods: {
    logout(){
         this.$store.dispatch("logout").then(() => {
              window.location.href = "/"
        });
    }, 
    setarPasso(valor,TipoDoc){
        this.$emit('setarTipoDocSelecionado',TipoDoc)
        this.$emit('setarPasso',valor)
    },
    lerQRCODE(){
        
        QRScanner.initiate({
        onResult: (result) => {
            console.log("RESULTADO DO SCANNER")
            console.log(result)
             },
        onError: function (err) { console.error('ERR :::: ', err); }, // optional
        onTimeout: function () { console.warn('TIMEOUT'); },
        timeout: 10000,
    });
    }
  },
  mounted() {
      this.tagSincronizada = localStorage.getItem('tag')
      this.apelido = localStorage.getItem('apelido').toUpperCase()
  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:red; */
        width:100vw;
        height:90vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#1d4596;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        margin-bottom:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>
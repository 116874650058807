<template>
    <div class="wout-login">
        <div class="center">
            <div>
                <img src="@/assets/img/pgtonegado.svg" 
                    width="auto" height="130" alt="Ops! Falha no reconhecimento facial!" crossorigin />
            </div>
            <span class="negado">Ops!<br>Houve falha na tentativa de reconhecimento facial!</span>
            <br>
            <button class="adicionar-btn"
                @click="voltar"
            >
                Tentar novamente
            </button>
        </div>
    </div>
</template>
<script>

export default {
    data: () => {
        return {
            
        }
    },
    methods: {
        voltar() {
            this.$emit('direcionar',{from: 'falhareconhecimento', to: 'self'})
        }
    }
}
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
}
.negado {
    font-weight: normal;
    color: white!important;
    padding: 10px;
}
.adicionar-btn {
    font-weight: normal;
    font-size: 0.95rem!important;
    color: white!important;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
}
</style>
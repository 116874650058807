<template>
  <v-navigation-drawer
    v-if="!this.$store.getters.hiddenDrawer"
    id="app-drawer"
    v-model="inputValue"
    app
    style="
      background-color: rgba(27, 27, 27, 0.87);
      top: 0px;
      z-index: 1000 !important;
    "
    floating
    temporary
    mobilebreak-point="991"
  >
    <v-img :src="image" height="100%">
      <v-layout style="min-height: 45px !important" tag="v-list" column>
        <v-list-item>
          <v-list-item-title class="title" style="color: white">
            <div
              style="display: flex; flex-direction: row; align-items: center"
            >
              <img
                v-if="imgSrc"
                :src="'data:' + imgTipo + ';base64,' + imgSrc"
                class="imgUpload"
                height="65"
                width="150"
                @click="setModalHoteis()"
              />
              <v-icon
                v-else
                x-large
                @click="setModalHoteis()"
                style="
                  cursor: pointer !important;
                  height: 65px;
                  width: 150px;
                  color: rgb(33, 150, 243);
                "
              >
                mdi-domain
              </v-icon>
              <v-btn
                class="default v-btn--simple ml-6"
                icon
                style="background-color: green; border-radius: 50% 50% 50% 50%"
                @click.stop="onClickBtn"
              >
                <v-icon style="color: white">mdi-arrow-left</v-icon>
              </v-btn>
            </div>
            <br />
            <hr />
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list nav dense style="max-height:90vh!important;overflow:auto;">
          <div v-for="link in menu" :key="link.id">

                  <v-list-item
                    v-if="!link.sublinks"
                    :to="link.to"
                    :active-class="color"                    
                    class="v-list-item"
                    style="color: white; max-height: 10px" >

                          <v-list-item-icon>
                            <v-icon color="white">{{ link.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title v-text="link.text" style="color: white" />
                  </v-list-item>

            <v-list-group
              v-else
              :key="link.text"
              :value="false" >

                            <v-icon slot="prependIcon" style="color: white!important;">{{link.icon}}</v-icon>

                            <template v-slot:activator>
                              <v-list-item-title v-text="link.text" style="color: white" 
                            />
                            </template>
                            <!-- <v-list-item :to="link.to" :key="link.text" class="ml-6" >
                              <v-list-item-icon>
                                <v-icon style="color: white">{{ link.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title style="color: white">{{ link.text }}</v-list-item-title>
                            </v-list-item> -->

                            <v-list-item
                              v-for="sublink in link.sublinks"
                              :to="sublink.to"
                              :key="sublink.text"
                              class="ml-6"
                              
                              
                            >
                              <v-list-item-icon>
                                <v-icon style="color: white">{{ sublink.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title style="color: white">{{ sublink.text }}</v-list-item-title>
                            </v-list-item>

            </v-list-group>
                
            
          </div>

          <div style="width:100%;padding-left:10px;padding-top:20px;">
              <v-btn small elevation="" @click="sairdosistema()" color="error" outlined>Sair</v-btn>
          </div>            
          
        </v-list>
        
      </v-layout>
      
      <div style="display:flex;justify-content:flex-end;flex-direction:column;padding:10px;">
        <div style="font-size:12px!important;color:#767676;">Versões: </div>
        <div style="font-size:12px!important;color:#767676;">Front:1.0.18</div>
      </div>
    </v-img>
    
    <v-dialog
      v-model="exibirModalHoteis"
      style="display: flex; justify-content: center; align-items: center"
    >
      <ModalHoteis
        @change="atualizarLogo($event)"
        v-bind:listaHoteis="listaDeHoteis"
      />
    </v-dialog>
  </v-navigation-drawer>
</template>
<script>

import ModalHoteis from "../DashViews/Modals/ModalHoteis";

// Utilities
import { mapMutations, mapState, mapActions } from "vuex";
import { tokenapies, BUILD_ID_CLIENTE,BUILD_APIES_URL } from '@/constantes.js'

export default {
  components: {
    ModalHoteis,
  },
  data: () => ({
    imgSrc: null,
    imgTipo: "",
    exibirModalHoteis: false,
    listaDeHoteis: [],
    hoteis: [],
    logo: require("@/assets/img/logo_p.png"),
    drawer: true,
    userName: localStorage.getItem("nome"),
    menu: [],
    links: [
      {
        to: "/",
        icon: "mdi-view-dashboard",
        text: "Dashboard",
      },
      {
        to: "/dashboard/user-profile",
        icon: "mdi-account",
        text: "Minha Conta",
      },
      {
        to: "/dashboard/table-list",
        icon: "mdi-clipboard-outline",
        text: "Table List",
      },
      {
        to: "/dashboard/user-tables",
        icon: "mdi-table-edit",
        text: "Usuários",
      },
      {
        to: "/dashboard/typography",
        icon: "mdi-format-font",
        text: "Fontes...",
      },
      {
        to: "/dashboard/icons",
        icon: "mdi-chart-bubble",
        text: "Ícones",
      },
      {
        to: "/dashboard/maps",
        icon: "mdi-map-marker",
        text: "Mapas",
      },
      {
        to: "/dashboard/notifications",
        icon: "mdi-bell",
        text: "Notificações",
      },
      {
        to: "/dashboard/hotel-profile",
        icon: "mdi-domain",
        text: "Hoteis",
      },
      {
        to: "/dashboard/captura-documento",
        icon: "mdi-credit-card",
        text: "Captura Documentos",
      },
      {
        to: "/dashboard/captura-documento-teste",
        icon: "mdi-credit-card",
        text: "Teste Captura",
      },
      {
        to: "/dashboard/modal-solicitacoes-teste",
        icon: "mdi-credit-card",
        text: "Teste Modal",
      },
    ],
    responsive: false,
  }),
  computed: {
    ...mapState("app", ["image", "color"]),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    items() {
      return this.$t("Layout.View.items");
    },
  },
  created() {
    this.loadHotel(localStorage.getItem("hotel"));
    this.loadMenu();
    this.loadHoteis();
  },
  mounted() {

    if(this.$store.getters.get_tipoacesso == 'fichadigital' ){
      this.$http
        .post("/perfil/usuario/select", {
          email: localStorage.getItem("useremail"),
        })
        .then((response) => {
          this.listaDeHoteis = response.data[0].hoteis;
        })
        .catch((error) => {
          console.log(error);
        });

      this.onResponsiveInverted();
      window.addEventListener("resize", this.onResponsiveInverted);

    }else{
          this.$store.dispatch("logout").then(() => { window.location.href = "/" });
    }

    //INCLUSAO DO CHAT
    // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    // console.log(Tawk_LoadStart)

    // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    // s1.async=true;
    // s1.src='https://embed.tawk.to/60af9787de99a4282a19e931/1f6mufr75';
    // s1.charset='UTF-8';
    // s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
    // document.querySelector('[title="chat widget"]').style.marginBottom = "40px";
    //FINAL DA INCLUSAO DO CHAT
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  methods: {
    sairdosistema(){
        this.$store.dispatch("logout").then(() => {
              window.location.href = "/"
        });
    },
    carregarImagem(id) {
      this.imgSrc = null;
      this.imgTipo = null;
      if (id) {
        this.$http
          .post("/imagem/select", { id: id })
          .then((response) => {
            if (response.status == 200) {
              var imgObj = response.data[0];
              if (imgObj) {
                this.imgSrc = Buffer.from(imgObj.dados).toString();
                this.imgTipo = imgObj.tipo;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    loadHoteis() {
      this.$http
        .post("/perfil/hotel/select", {
          idioma: localStorage.getItem("idioma"),
        })
        .then((response) => {
          this.hoteis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadHotel(idhotel) {
      this.$http
        .post("/perfil/hotel/select", {
          id: idhotel,
          idioma: localStorage.getItem("idioma"),
        })
        .then((response) => {
          this.carregarImagem(response.data[0]["logo"]);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setModalHoteis() {
      this.exibirModalHoteis = !this.exibirModalHoteis;
    },
    carregarAtivarImportacoes() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: 'ATIVARIMPORTACOES',
        })
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data&&resp.data.length>0) {
              localStorage.setItem("ativarimportacoes", resp.data[0].valor);
            }
          } else {
            console.error("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    atualizarLogo(hotel) {
      this.setModalHoteis();
      var idhotel = -1;
      var idhotelpms = -1;
      var logo = -1;
      for (var i = 0; i < this.hoteis.length; i++) {
        var objHotel = this.hoteis[i];
        if (objHotel) {
          if (objHotel.nome == hotel) {
            idhotel = this.hoteis[i]["id"];
            idhotelpms = this.hoteis[i]["idhotelpms"];
            logo = this.hoteis[i]["logo"];
            localStorage.setItem('nomehotel',objHotel.nome)
            break;
          }
        }
      }
      if (idhotel) {
        localStorage.setItem("hotel", idhotel);
        localStorage.setItem("idhotelpms", idhotelpms);
        this.carregarAtivarImportacoes();
      }
      if (logo) {
        this.carregarImagem(logo);
      } else {
        this.imgSrc = null;
        this.imgTipo = "";
      }
      window.location.reload(true)
    },
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    ...mapActions(["logout"]),
    async loadMenu() {
      this.$http("/auth/listamenu")
        .then(async (response) => {
          if (response.status == 200) {
            //console.log("REPOSNE DATA")
            //console.log(JSON.stringify(response.data,null,4))
            this.menu = response.data;
            
            let baseURL = BUILD_APIES_URL
            await this.$http.post(
                baseURL+"/modulos/selecionar-relacao", 
                {idsistema: 1, idcliente: BUILD_ID_CLIENTE},
                {headers:{'x-access-token':tokenapies}})
                .then((resp) => {
                  if (resp.status == 200) {
                    let modulosdisponiveis = []
                    resp.data.modulos.map(modulo => {
                      resp.data.relacoes.map(relacao => {
                        if(modulo.id == relacao.relacao.idmodulo){
                          modulosdisponiveis.push(modulo.id)
                        }
                      })
                    })
                    this.menu = this.menu.filter(item => {
                      if(item.idmodulo != null){
                        if(modulosdisponiveis.includes(item.idmodulo)){
                          return true
                        }else{
                          return false
                        }                  
                      }else{
                        return true
                      }
                    })
                  }
                })
                .catch((error) => {
                  alert(error);
                });

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /*     loadMenu() {
      this.$http
        .post("/menu/select", { id: 50, idioma: localStorage.getItem('idioma') })
        .then((response) => {
          this.menu = response.data;
          const obj = JSON.parse(this.menu[0]["objeto"]);
          this.links = obj.children;
        })
        .catch((error) => {
          console.log(error);
        });
    }, */
  },
};
</script>


<style lang="scss">
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }

  .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>

<template>
  <v-app>
    <v-main style="padding: 60px 0px 0px 0px !important; padding: 0px 0px 0px; !important;">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    ativarImportacoes: localStorage.getItem('ativarimportacoes')
  }),
  components: {
  },
  methods:{
    carregarTipoIntegracao() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: 'TIPOINTEGRACAO',
        })
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data&&resp.data.length>0) {
              localStorage.setItem("TIPOINTEGRACAO", resp.data[0].valor);
            }
          } else {
            console.error("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadMenu() {
      this.$http
        .post("/menu/select", { id: 50, idioma: "pt-BR" })
        .then((response) => {
          if (response.status == 200) {
            //let test = response.data
            //console.log(test[0])
          }
        })
        .catch((error) => {
          console.log(error);
          //window.location.href = '/'
        });
    }
  },
  mounted(){
    this.loadMenu();
    this.carregarTipoIntegracao();
    this.sockets.subscribe("backend_to_dash_importar_reservas_retorno", (dados) => {
      localStorage.setItem('processando', false);
      localStorage.setItem('resultado', dados.retorno+' '+JSON.stringify(dados.periodo));
      window.dispatchEvent(new CustomEvent('processando-changed', {
        detail: {
          processando: localStorage.getItem('processando'),
          resultado: localStorage.getItem('resultado'),
        }
      }));      
    });
    this.sockets.subscribe("backend_to_dash_importar_paises", (dados) => {
      localStorage.setItem('processando_paises', false);
      localStorage.setItem('resultado_paises', dados.retorno.includes('OK')?'Importação concluída.':'Ocorreu um erro na importação.');
      window.dispatchEvent(new CustomEvent('processando-paises-changed', {
        detail: {
          processando_paises: localStorage.getItem('processando_paises'),
          resultado_paises: localStorage.getItem('resultado_paises'),
        }
      }));      
    });
    this.sockets.subscribe("backend_to_dash_importar_estados", (dados) => {
      localStorage.setItem('processando_estados', false);
      localStorage.setItem('resultado_estados', dados.retorno.includes('OK')?'Importação concluída.':'Ocorreu um erro na importação.');
      window.dispatchEvent(new CustomEvent('processando-estados-changed', {
        detail: {
          processando_estados: localStorage.getItem('processando_estados'),
          resultado_estados: localStorage.getItem('resultado_estados'),
        }
      }));
    });
    this.sockets.subscribe("backend_to_dash_importar_cidades", (dados) => {
      localStorage.setItem('processando_cidades', false);
      localStorage.setItem('resultado_cidades', dados.retorno.includes('OK')?'Importação concluída.':'Ocorreu um erro na importação.');
      window.dispatchEvent(new CustomEvent('processando-cidades-changed', {
        detail: {
          processando_cidades: localStorage.getItem('processando_cidades'),
          resultado_cidades: localStorage.getItem('resultado_cidades'),
        }
      }));
    });
    this.sockets.subscribe("backend_to_dash_importar_profissoes", (dados) => {
      localStorage.setItem('processando_profissoes', false);
      localStorage.setItem('resultado_profissoes', dados.retorno.includes('OK')?'Importação concluída.':'Ocorreu um erro na importação.');
      window.dispatchEvent(new CustomEvent('processando-profissoes-changed', {
        detail: {
          processando_profissoes: localStorage.getItem('processando_profissoes'),
          resultado_profissoes: localStorage.getItem('resultado_profissoes'),
        }
      }));
    });
    this.sockets.subscribe("backend_to_dash_importar_status", (dados) => {
      localStorage.setItem('processando_status', false);
      localStorage.setItem('resultado_status', dados.retorno.includes('OK')?'Importação concluída.':'Ocorreu um erro na importação.');
      window.dispatchEvent(new CustomEvent('processando-status-changed', {
        detail: {
          processando_status: localStorage.getItem('processando_status'),
          resultado_status: localStorage.getItem('resultado_status'),
        }
      }));
    });
  }
}
</script>

<style>
:root {
  /* Testar fontes: 
    'Balsamiq Sans', 'Noto Sans',  'Cairo', 'Roboto' 
    'Merienda', 'Varela Round', 'Fredoka One'
    Combinar com bold/normal/700
  */
  --font-principal: 'Varela Round', sans-serif!important;
  --font-weight-principal: bold!important;
  /* --font-weight-principal: normal; */
  /* --font-weight-principal: 200; */
  --font-size: 0.7rem;
}

.v-label.v-label--active.theme--light.primary--text {
  color: #8C8B99 !important;
}
.v-application--is-ltr .v-text-field .v-label {
      color: #8C8B99 !important;
}
.v-application {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: var(--font-size);
}

div {
  font-family: var(--font-principal);
  font-size: 1.1rem;
}

h1 {
  font-family: var(--font-principal);
  font-size: 1.10rem!important;
}

h2 {
  font-family: var(--font-principal);
  font-size: 1.05rem!important;
}

h3 {
  font-family: var(--font-principal);
  font-size: 0.90rem!important;
}

h4 {
  font-family: var(--font-principal);
  font-size: 0.85rem!important;
}

input {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: 0.9rem!important;
}

textarea {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: 1.1rem!important;
}

td {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: 0.9rem!important;
}

label {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: 0.8rem!important;
}

.labels {
  font-family: var(--font-principal)!important;
  font-weight: var(--font-weight-principal);
  font-size: 0.8rem!important;
}

.v-label {
  font-family: var(--font-principal);
  font-size: 0.8rem!important;
}


.v-messages__message {
  font-size: 0.8rem!important;
}

.v-sheet {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: var(--font-size);
}

.v-list-item__title {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: 0.8rem!important;
}

.v-list-item__content {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: 1.0rem;
}

button {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: 1.1rem!important;
}

.text-start {
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: 0.8rem!important;
}

.title {
  margin-bottom: 2px;
}

.toolbar-title {
  font-weight: bold;
  font-size: 1.2rem!important;
}

.legenda {
  font-weight: bold;
  font-size: 0.8rem!important;
}

.category {
  font-size: var(--font-size);
}

.v-data-footer__select
  , .v-data-footer__pagination
  , .v-select__selection {
  font-size: 0.8rem;
}

.v-card--material__header {
  background-color:#2196f3!important;
  padding-left:10px;
  font-weight: var(--font-weight-principal);
  font-size: var(--font-size);
}

.v-card__title {
  background-color:#2196f3!important;
  color: white !important;
  font-size: 1.2rem;
}

.v-card__subtitle{
  background-color:#2196f3!important;
  color: white !important;
  margin: 0;
  padding: 0;
}

.v-btn__content {
  font-family: var(--font-principal);
  font-size: var(--font-size);
}
.v-toolbar__content{
  color: white;
  font-family: var(--font-principal);
  font-size: 0.9rem!important;
}
.v-toolbar__title{
  color: white;
  font-family: var(--font-principal);
  font-weight: var(--font-weight-principal);
  font-size: var(--font-size);
}
.v-toolbar__content{
  color:white;
  font-family: var(--font-principal);
  font-size: var(--font-size);
}
.v-label{
  font-family: var(--font-principal);
  font-size: 0.9rem!important;
}
.v-text-field__slot{
  font-family: var(--font-principal);
  font-size: var(--font-size)!important;
}
.vdatetime-input {
  font-size: 1.2rem!important;
}
.next {
  padding: 1.5vh;
  border-radius: 50%;
}

</style>

<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <div class="wout-topo">
            <div style="width:15%;">
                <button class="menu-voltar-btn" @click="voltar()">
                    <v-icon color="white">mdi-arrow-left</v-icon>
                </button>
            </div>
            <div class="white--text text-subtitle-2 px-2" style="width:70%;">
                <div v-if="dados.nome">{{dados.nome}}</div>
                <div class="wout-apto-reserva" v-if="dados">Apto.{{dados.uh}} Reserva: {{dados.numeroreserva}}</div>
            </div>
            <div class="wout-power">
                <v-icon style="font-size: 28px!important;" color="white" @click="logout">mdi-power</v-icon>
            </div>
        </div>
        <!-- Conteúdo -->
        <div class="wout-conteudo">
            <!-- Resumo -->
            <br>
            <div class="cartao" >
                <div class="wout-row">
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-in:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckinprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-out:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckoutprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="toggler">Informe o consumo do frigobar<v-icon>mdi-chevron-down</v-icon></div>
            <!-- Itens do Frigobar -->
            <div class="scrollable">
                <div class="cartao-item" v-for="(item, index) in dados.itens" :key="index">
                    <div class="wout-row">
                        <div class="item-label" style="width:20%;padding:0px;padding-left:0.5rem;">Cod.:</div>
                        <div class="item-label" style="width:40%;padding:0px;padding-left:0.5rem;">Descrição:</div>
                        <div class="item-label" style="width:20%;padding:0px;padding-left:0.5rem;">Valor:</div>
                        <div class="item-label" style="width:20%;padding:0px;padding-left:0.5rem;">Quantidade:</div>
                    </div>
                    <div class="wout-row">
                        <div class="item-value" style="width:20%;">
                            <span>{{item.codproduto}}</span>
                        </div>
                        <div class="item-value" style="width:40%;">
                            <span>{{item.descprod}}</span>
                        </div>
                        <div class="item-money" style="width:20%;">
                            <span>R$ {{item.preco | formatarMoeda}}</span>
                        </div>
                        <div class="item-value" style="width:20%;">
                            <span :key="inputKey">{{item.quantidade}}</span>
                            <div class="col-btn">
                                <button @click="incrementar(index)">
                                    <v-icon small color="blue">mdi-arrow-up</v-icon>
                                </button>
                            </div>
                            <div class="col-btn">
                                <button @click="decrementar(index)">
                                    <v-icon small color="blue">mdi-arrow-down</v-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Registrar Consumo -->
            <div style="display:flex;justify-content:center;padding-top:3vh;">
                <button class="pagar-btn" @click="registrarConsumo()" :disabled="!possuiConsumo">
                    <v-icon>mdi-fridge-industrial-outline </v-icon>
                    Registrar Consumo
                    <v-icon>mdi-arrow-right</v-icon>
                </button>
            </div>
            <!-- Dialogo Informativo -->
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600" v-model="infoMessage"
            >
                <v-card rounded>
                    <v-card-text>
                        <div style="text-align:center;">
                            <img src="@/assets/img/contestwout.png" 
                                width="150" height="auto"
                                alt="Contesting..." crossorigin />
                                <br>
                            <div class="contest-message">Consumo registrado com sucesso!
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <!-- Voltar -->
                        <button class="voltar-btn"
                            v-on:click="fecharEVoltar()"
                            id="start_button"
                        >
                            <span class="voltar-icon">&nbsp;&nbsp;&nbsp;</span>
                            Voltar
                        </button>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-dialog>        

        </div>
    </div>
</template>

<script>
export default {
    props:["dados"],
    data: () =>{
        return{
            inputKey: 0,
            possuiConsumo: false,
            infoMessage: false
        }
    },
    components: {
		
	},
    methods: {
        fecharEVoltar() {
            this.infoMessage = false
            this.$emit('direcionar', {to:'inicio', from:'consumofrigobar'})
        },
        incrementar(index) {
            this.dados.itens[index].quantidade = parseInt(this.dados.itens[index].quantidade) + 1
            this.inputKey++
            this.atualizarConsumo()
        },
        decrementar(index) {
            if (parseInt(this.dados.itens[index].quantidade) > 0) {
                this.dados.itens[index].quantidade = parseInt(this.dados.itens[index].quantidade) - 1
                this.inputKey++
                this.atualizarConsumo()
            }
        },
        atualizarConsumo() {
            var result = false
            for (var i = 0; i < this.dados.itens.length; i++) {
                if (parseInt(this.dados.itens[i].quantidade) > 0) {
                    result = true
                    break
                }
            }
            this.possuiConsumo = result
        },
        logout() {
            this.$emit('direcionar', {to:'login', from:'individual'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio', from:'consumofrigobar'})
        },
        registrarConsumo() {
            var consumos = []
            for (var i = 0; i < this.dados.itens.length; i++) {
                var item = this.dados.itens[i]
                if (parseInt(item.quantidade) > 0) {
                    consumos.push({
                        "CODPRODUTO": item.codproduto,
                        "PRECOUNITARIO": item.preco,
                        "QUANTIDADE": item.quantidade,
                        "DESCPROD": item.descprod,
                        "CODUH": this.dados.uh,
                        "IDLANCAMENTO":null
                    })
                }
            }
            var params = {
                "consumos": consumos,
                "idreservahospede": this.dados.idreservahospede,
                "emailusuario": this.dados.email,
                "numeroreserva": this.dados.numeroreserva,
                "idhotel": this.dados.idhotel
            }
            this.$http.post("/webcheckout/adicionarConsumoFrigobar", params, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                .then(result => {
                    if (result.status == 200) {
                        this.infoMessage = true
                    } else {
                        console.error(JSON.stringify(result))
                    }
                })
            .catch((e) => {
                console.error(e)
            })            
        }
    },
    computed: {
        //
    },
    mounted(){
        //        
    }
}
</script>

<style>

</style>
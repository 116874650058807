var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"color":"green","title":"Simple Table","text":"Here is a subtitle for this table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text-success text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.country))]),_c('td',[_vm._v(_vm._s(item.city))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.salary))])]}}])})],1)],1),_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"color":"green","flat":"","full-width":"","title":"Table on Plain Background","text":"Here is a subtitle for this table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.slice(0, 7),"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.country))]),_c('td',[_vm._v(_vm._s(item.city))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(item.salary))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-dialog',{staticStyle:{"min-height":"90vh!important"},attrs:{"width":"70%","persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"pa-2",attrs:{"height":"100%"}},[_c('v-toolbar',{staticClass:"ma-2",staticStyle:{"color":"white","display":"flex","flex-direction":"column","padding-top":"5px","padding-bottom":"5px"},attrs:{"color":"light-blue"}},[_c('div',{staticStyle:{"min-width":"100%!important","display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"toolbar-title"},[_vm._v(" ítens do Pagamento ")])])]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footerProps,"search":_vm.search},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}},{key:"item.datalancamentopms",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatarDataYYHora")(item.datalancamentopms))+" ")]}},{key:"item.precounit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"item-value"},[_vm._v(_vm._s(_vm._f("formatarMoeda")(item.precounit)))])]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"item-value"},[_vm._v(_vm._s(_vm._f("formatarMoeda")(item.quantidade)))])]}},{key:"item.valortotal",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"item-value"},[_vm._v(_vm._s(_vm._f("formatarMoeda")(item.valortotal)))])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"align-center":"","justify-center":"","color":"primary"},on:{"click":function($event){return _vm.$emit('closeitems')}}},[_vm._v("Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="wout-login">
        <div class="center">
            <div>
                <img src="@/assets/img/desconto.svg" 
                    width="auto" height="130" alt="Desconto!" crossorigin />
            </div>
            <span class="desconto">Hóspede {{dados.nomehotel}} tem desconto para próxima hospedagem!</span>
        </div>
    </div>
</template>
<script>

export default {
    props:["dados"],
    data: () => {
        return {
            
        }
    }
}
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.desconto {
    font-weight: normal;
    color: white!important;
    padding: 5px;
    width: 60vw;
    text-align: center;
}
</style>
<template>

    <div class="" style="flex-wrap:wrap;width:100%; overflow:auto;">
       
        <h2 style="text-align:center;margin-bottom:30px;">{{pergunta}}</h2>
        <div style="display:flex;flex-wrap:wrap;justify-content:center;" >

            <!-- <div 
              :style="(solicitacao.selecionado ?'background-color:#31B44A;color:white;':'background-color:#c5c5c5;color:black;')+'cursor:pointer;min-height:50px;max-width:40vw;min-width:200px;margin-right:10px;margin-top:10px;border-radius:5px;font-size:16px;font-family:Arial, Helvetica, sans-serif;display:flex;flex-direction:column;justify-content:center;align-items:center;'"
              v-for="solicitacao in solicitacoesTipo" :key="solicitacao.id"
              @click="marcarDesmarcarSolicitacao(solicitacao.id)"
              
              >
              {{solicitacao.descricao}}

            </div> -->

             <div 
              :style="(resposta.selecionado ?'background-color:#31B44A;color:white;':'background-color:#c5c5c5;color:black;')+'cursor:pointer;min-height:50px;max-width:40vw;min-width:200px;margin-right:10px;margin-top:10px;border-radius:5px;font-size:16px;font-family:Arial, Helvetica, sans-serif;display:flex;flex-direction:column;justify-content:center;align-items:center;'"
              v-for="(resposta,index) in respostas" :key="index"
              @click="marcarDesmarcarSolicitacao(resposta.texto)"
              :data-cy="`resposta_${resposta.texto}`"
              
              >
              {{resposta.texto}}

            </div>

            
        </div>

        <div class="cardInputs" style="margin-top:40px;">
            <textarea id="texto-obs" name=""  data-cy="textarea-resposta" v-model="obs" style="background-color:white;border-radius:10px;padding:10px;"  cols="30" rows="5">
            </textarea>
        </div>


        <div class="cardInputsLinhaBetween" style="">
            <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-perguntavoltar">
                    {{ $t('webcheckin.perguntaextra.voltar') }}
            </button>

            <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-perguntaavancar">
                    {{ $t('webcheckin.perguntaextra.avancar') }}
            </button>
        </div> 

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}
            <v-btn text @click="snack = false">Close</v-btn>
        </v-snackbar>

    </div> 

</template>

<script>

export default {
    name: 'PerguntaExtra',
    props:["dados","dadosAcompanhantes","hospedePrincipal","hospedePrincipalInicial"],
    data: () =>{
        return{
            solicitacoesTipo:[],
            solicitacoesUsuario:[],
            obs:null,
            snack: false,
            snackColor: '',
            snackText: '',

            pergunta:null,
            objetoPerguntaRetornado:null,
            objetoRespostaRetornado:null,
            respostas:[],
            resposta:'',
        }
    },
    watch:{
        respostas(valor){
            this.respostas = valor
        }
    },
    mounted(){
        this.buscarPerguntas()
        //this.obs = this.dados.obs
    },
    methods: {
        buscarPerguntas(){
            this.$http.post("formulario/select",{chave:'PERGUNTAEXTRA'}).then(async resp =>{
                    this.objetoPerguntaRetornado = resp.data[0]
                    this.pergunta = resp.data[0].perguntas[0].texto
                    this.respostas = resp.data[0].perguntas[0].respostasaceitas.tipo.split(';')
       
                    this.respostas = await this.respostas.map(p => {
                        p = {texto:p.toUpperCase()}
                        // this.resposta += p+' ; '
                        return p;
                    })

                    this.buscarResposta()
                    // if(this.resposta[this.resposta.length - 2].trim() == ';'){
                    //     this.resposta = this.resposta.substr(0,this.resposta.length-3)
                    // }

            })
        },
        buscarResposta(){
            let objeto = {
                id:this.objetoPerguntaRetornado.id,
                resposta:{
                    pergunta:{id:this.objetoPerguntaRetornado.perguntas[0].id},
                    idrespondente:this.dados.idpessoa
                }
            }

            this.$http.post("formulario/resposta/select",objeto).then(async resp =>{

                this.objetoRespostaRetornado = resp.data[0]
                let novoobjetoresposta = []
                this.obs = resp.data[0].respostas[0].observacao
                for(var i =0; i < this.respostas.length; i++){

                    if(this.respostas[i].texto.toUpperCase() == resp.data[0].respostas[0].texto.toUpperCase()){
                        this.respostas[i].selecionado = true
                        novoobjetoresposta[i] = this.respostas[i]
                    }else{
                        novoobjetoresposta[i] = this.respostas[i]
                    }
                }
                this.respostas = null
                this.respostas = novoobjetoresposta
            }).catch(() =>{
                this.objetoRespostaRetornado = null
            })
        },
        async marcarDesmarcarSolicitacao(texto) {

            this.respostas = await this.respostas.map(p => {
                        p.selecionado = false
                        // this.resposta += p+' ; '
                        return p;
                    })
 
            this.respostas.find(x => x.texto === texto).selecionado = !this.respostas.find(x => x.texto === texto).selecionado
            let objetonovo = this.respostas.find(x => x.texto === texto)
            let index = this.respostas.findIndex(x => x.texto === texto)
            this.respostas.splice(index, 0)
            this.respostas[index] = objetonovo 


        },

        async salvarSolicitacoes() {
            // var concluidoComSucesso = false
            // var descricaoErro = ""
            // var contadorAuxiliar = 0;
            // alert(this.respostas)
            for(var i =0; i < this.respostas.length; i++){
               
                if(this.respostas[i].selecionado){
                    //  alert('selecionado')
                    var bodyresposta = null
                    if(this.objetoRespostaRetornado){
                        //atualizar

                        bodyresposta = {
                            id:this.objetoRespostaRetornado.respostas[0].id,
                            pergunta : this.objetoPerguntaRetornado.perguntas[0].id,
                            resposta : { "resposta":this.respostas[i].texto},
                            respondente: this.dados.idpessoa,
                            observacao: this.obs,
                            ativo: true,
                            idioma: 'pt-BR'
                        }


                    }else{
                        //inserir
                        bodyresposta = {
                            pergunta : this.objetoPerguntaRetornado.perguntas[0].id,
                            resposta : { "resposta":this.respostas[i].texto},
                            respondente: this.dados.idpessoa,
                            observacao: this.obs,
                            ativo: true,
                            idioma: 'pt-BR'
                        }
                        
                            // .catch(() =>{
                            //     this.objetoRespostaRetornado = null
                            // })

                    }
                    console.error(`bodyresposta: ${JSON.stringify(bodyresposta)}`)
                    this.$http.post("formulario/resposta/upinsert",bodyresposta).then(() =>{
                            console.log("ok")
                    }).catch(error =>{
                        console.log(error)
                    })

                }

                
            }


            // alert("concluidoComSucesso "+concluidoComSucesso)
            // console.log("descricaoErro "+descricaoErro)
        },

        async avancar(){
                await this.salvarSolicitacoes()
                var dadossalvar = this.dados
                dadossalvar.obs = this.dados.obs +" // "+this.obs


                // if(this.hospedePrincipalInicial && this.dadosAcompanhantes && this.dadosAcompanhantes.length > 0){
                if(this.dadosAcompanhantes && this.dadosAcompanhantes.length > 1){
                    this.$emit('salvarDados',dadossalvar,'hospedes')   
                }else{
                    this.$emit('salvarDados',dadossalvar,'fim')   
                }
                


        },
        voltar(){

            if(this.dados.exibirPassosHorario){
                this.$emit('direcionar','horario')
            }else{
                if(this.dados.exibirPassosObservacao){
                    this.$emit('direcionar','observacoes')
                }else{
                    if(this.dados.exibirPassosCovid){
                        this.$emit('direcionar','formulariocovid')
                    }else{
                        this.$emit('direcionar','assinatura')
                    }
                }
            }
            
        }


    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
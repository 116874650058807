<template>
        <v-card>
        <v-toolbar color="blue">
          <span style="width:90%">Vamos começar o Checkin Pesquisando pelo Documento</span>
          <span style="width:5%;padding:10px;"> 
            <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Verifica CPF"/>
            </span>
          <span style="width:5%"> 
              <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
                  <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
          </span>
        </v-toolbar>
        <div style="color:orange;padding-left:10px;text-align:center;"><span v-if="!possuiEmpresaReservante">Bloqueado para edição: sem empresa reservante.</span></div>
        <v-card-text style="">
          
      

<v-row style="display: flex" class="pa-2">
            <v-text-field
              class="ma-2"
              label="Numero do Documento"
              append-icon="mdi-card-bulleted-outline"
              style="max-width: 300px; padding-top: 5px"
              v-model="numerodocumento"
            />

            <v-btn style="color:white;font-weight:bold" 
              color="primary"
              medium
              class="mb-4 mt-4"
              @click="buscarHospedesComDoc()"
              > Buscar Hospede</v-btn
            >
            
          </v-row>


        </v-card-text>
        
        <v-card-text>
            <v-data-table
            :headers="headers"
            :items="hospedes"
            :rows-per-page="5"
            :search="search"
            item-key="nome"
            loading-text="Carregando... Por favor, aguarde."
          >
            <template v-slot:no-data>
                0 Registros
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                  <v-btn small elevation="" @click="trocarPessoaDoc(item)" color="primary">Substituir a Pessoa atual por esta</v-btn>
              
            </template>

            </v-data-table>
            <div v-if="importandoCPF"><span>{{importandoCPF}}</span></div>
        </v-card-text>
        
        
        </v-card>
</template>
<script>

// import {serializarImagem} from '../../utils/serializar'

// import ModalZoomImagem from '../DashViews/Modals/ModalZoomImagem'

import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
  name: "VerificarPeloCPF",
  data: () => ({
    ladodocnome:null,
    principal:null,
    ladodocfk:null,
    image: '',
    imgSrc:'',
    imgSrcFrente:'',
    imgSrcVerso:'',
    isImg:false,
    exibirModalZoom:false,
    imgZoom:null,
    btnSalvarHabilidado:true,
    headers: [
      { text: "Reserva", value: "numeroreserva" },
      { text: "Nome", value: "nome" },
      { text: "Sobrenome", value: "sobrenome" },
      { text: "Ações", value: "actions", sortable: false, align: 'center' }
    ],
    hospedes:[],
    numerodocumento:'',
    search:'',
    importandoCPF: null,
    dadosOutraPessoa: null,
    exibirModalTutorial: false
  }),
  components: {
    // ModalZoomImagem
    BotaoTutorial
  },
  props: ["id", "idhospede", "idReservaHospede","idreserva", "possuiEmpresaReservante","numeroreserva"],
  methods :{
    async trocarPessoaDoc(item){
        let dados = {
            idpessoabase:item.idpessoa,
            idhospedebase:item.idhospede,
            idhospede:this.idhospede
        }
        await this.$http.post("/reserva/trocarPessoaDoc",dados).then(()=>{
             this.$emit("avancar");
             this.$emit("update")
        }).catch(()=>{
            alert("Não foi possível realizar a troca de hospede")
        })
    },
    async buscarHospedesComDoc() {

        if(this.numerodocumento && this.numerodocumento != ''){
          let params = {
            numerodocumento: this.numerodocumento.replace('.','').replace('.','').replace('.','').replace('-','').replace('-','').replace('-',''),
            numeroreserva: this.numeroreserva,
            useremail: localStorage.getItem('useremail'),
            idhotelpms: localStorage.getItem('idhotelpms')
          }
          await this.$http.post("/reserva/buscarhospedescomdoc", params, {timeout:60000 })
            .then((response) => {
              if(response.status == 200){
                  if(response.data.length > 0){
                      this.hospedes = response.data
                  }else{
                      alert('Hospede não Encontrado ou já possui um hospede na reserva atual com o documento')
                  }
              }else{
                if (response.status == 204) {
                  this.importandoCPF = "Verificando se o documento existe no PMS...";
                  this.sockets.subscribe("backend_to_dash_importar_pessoa_fisica_retorno", (dados) => {
                    //console.error(JSON.stringify(dados))
                    this.importandoCPF = null;
                    if (dados.id) {
                      this.dadosOutraPessoa = dados
                    } else {
                      this.importandoCPF = 'Documento não encontrado no PMS.';
                      setTimeout(()=>{this.importandoCPF = null}, 2000)
                    }
                  });
                }
              }
            })
            .catch((error) => {
                console.log(error.response);
            });
        }else{
            alert('Informe um numero de documento válido')
        }
      
        
    },

    setModalTutorial(){
        this.exibirModalTutorial = !this.exibirModalTutorial
    },

  

  },
  watch: {
    idhospede() {
      this.exibirModalZoom = false
      this.imgZoom = null
      this.listarDados()
    },
  },
  mounted(){
    

  }

};
</script>

<style scoped>
.imgUpload{
        max-width:200px;
        max-height: 200px;
        max-width:90vw;
        margin-top:20px;
        margin-left:20px;
        margin-right:20px;
    }
</style>
<template>
        
        <div class="py-4" style="flex-wrap:wrap;width:100%;">
           <h1 style="text-align:center;" >😊</h1>
           <div class="cardInputs">
                <label class="labels" style="text-align:center;line-height: 1.5;">Envie uma Selfie</label>
            </div>

            

            <div class="cardInputs" style="display:flex;justify-content:center;align-items:center;">

                        <!-- <img v-if="imgSrc" :src="'data:image.png;base64,' + imgSrc" class="imgUpload" /> -->
                        <img v-if="imgSrc" :src="'data:image.png;base64,' + imgSrc" class="imgUpload"  style="max-height:40vh;" />

            </div>

            <input id="file-upload" type="file" data-cy="btn-uploadfrente" style="display: none" accept="image/*" @change="onFileChange"/>
            <div style="display:flex;justify-content:center;">
                <label for="file-upload" style="background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;">
                    <span data-cy="span-cliqueaqui" style="margin-right:10px;font-size:18px;font-weight:bold;color:white;">{{ $t('webcheckin.fotofrente.cliqueaqui') }}</span>
                    <i style="color:white!important;" class="fa fa-search"></i>
                </label> 
            </div>

            <div class="cardInputsLinhaBetween" style="">
                <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-frentevoltar">
                      {{ $t('webcheckin.fotofrente.voltar') }}
                </button>

                <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-frenteavancar">
                        {{ $t('webcheckin.fotofrente.avancar') }}
                </button>
            </div> 
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                    <span>{{ snackText }}</span>
                    <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
            <v-dialog v-model="loading" width="50%"  style="min-height:90vh;">
                <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">
                    
                    <h1>Aguarde!</h1>
                    <div>Salvando seus dados...</div>
                    
                </v-card>
            </v-dialog>



        </div>
        

</template>

<script>

import {serializarImagemNew} from '../../utils/serializar'

export default {
    props:["dados"],
    data: () =>{
        return{
            isImg: null,
            imgSrc: null,
            imgTipo: null,
            snack: false,
            snackColor: '',
            snackText: '',
            loading:false,
            btnMessage:'Clique Aqui Para Enviar',
            lendoDoc: false
        }
    },
    mounted(){
        
        this.carregarDados()
       
    },
    methods: {

        async onFileChange(e) {

            try {

                this.btnMessage = 'Aguarde, Carregando...'
                this.lendoDoc = true
                this.loading =  true

                serializarImagemNew(e).then(async resp =>{
                    this.imgSrc = resp.dados
                    await this.salvaFotoFrente(resp.tipo);
                    this.lendoDoc = false

                }).catch(error =>{
                    this.btnMessage = 'Clique Aqui Para Enviar'
                    this.lendoDoc = false
                    this.loading =  false
                    this.snack = true
                    this.snackColor = "error" 
                    this.snackText = error.message
                })

            } catch (error) {
                console.log('error ',error)
            }
            
           
        },
      
        async salvaFotoFrente(tipoImg) {

            try {
                
                    if (!this.imgSrc.length > 0) {
                        console.log("invalido");
                        return;
                    }
                    
                    

                    let dados = {
                        "tipo" : tipoImg,
                        "nome" : "Selfie",
                        "dados" : this.imgSrc,
                        "tabela": "hospede",
                        "fk": "selfie",
                        "chave": "id",
                        "valor": this.dados.idhospede
                    };


                    await this.$http.post("/imagem/upinsert", dados, { timeout: 100000 })
                        .then((response) => {

                            if (response.status == 200) {
                                console.log("Foto do Usuario Salvo com Sucesso!");
                            } else {
                                console.log("Não foi possivel salvar a Foto do Usuario!");
                            }
                            
                            this.loading = false
                        })
                        .catch((error) => {
                            this.loading = false
                            console.log(error.response);
                        });


            } catch (error) {
                console.log('error ',error)
            }

        },
        validarFormulario(){

            if(!this.imgSrc){ 
                alert("Imagem Selfie é Obrigatorio")
                return false
            }

            return true
            
        },
        
        salvarDados(){
            
            

        },
        async avancar(){
            
            
                if( await this.validarFormulario()){
                    this.modalCarregando = true
                    this.$emit('direcionar','dadopessoal')
                }  

            
        },
        voltar(){
           
            // this.$emit('direcionar','contato')

            if(this.dados.crianca){
                this.$emit('direcionar','nacionalidade')
            }else{
                this.$emit('direcionar','contato')
            }
            
        },

        carregarDados(){
            // alert("this.dados.docfrente")
            // alert(this.dados.docfrente)
            this.$http.post("/imagem/select", {id:this.dados.selfie}, { timeout: 60000 })
                .then((response) => {
                    // alert("OK")
                    if (response.status == 200) {
  
                        // this.imgSrc = String.fromCharCode.apply(
                        //     null,
                        //     response.data[0].dados.data
                        // );
                        if(response.data.length > 0){
                            this.imgSrc = Buffer.from(response.data[0].dados).toString()

                            this.imgTipo = response.data[0].tipo;
                        }

                    }
                })
                .catch((error) => {
                    alert(error)
                    console.log(error.response);
                });
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
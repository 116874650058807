<template>
  <div>
    <v-card height="480">
      <v-toolbar style="" color="blue">
        <span style="width:10%;">Dados Pessoais</span>
        <span style="width:20%;text-align:right;padding-right:50px;">
          <v-btn @click="exibirCopiar">Replicar Dados</v-btn>
          <!-- <v-btn @click="testarSocketContestacao()" >testeSocket</v-btn> -->
        </span>
        <span style="width:65%;text-align:right;padding-right:50px;">
          <v-btn v-if="dadosMergeEncontrado" @click="exibirMerge = true" style="width:60%;background-color:#db1010;color:white;">Dados Divergentes</v-btn>
        </span>
        <span style="width:5%;padding:10px;"> 
            <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Dados Pessoais"/>
        </span>
        <span style="width:5%"> 
            <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
                <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
        </span>
      </v-toolbar>
      <div style="color:orange;padding-left:10px;text-align:center;"><span v-if="!possuiEmpresaReservante">Bloqueado para edição: sem empresa reservante.</span></div>
      <v-form v-model="valid" lazy-validation ref="form">
        <v-card-text>
          <v-row style="display: flex" class="pa-2">
            <v-text-field
              class="ma-2"
              label="Nome"
              append-icon="mdi-account"
              style="width: 200px; padding-top: 5px"
              v-model="nome"
              @keyup="[validate(), nameToUpper()]"
              @blur="validate"
              :rules="nameRules"
              required
              data-cy="dadosp-nome"
            />
            <v-text-field v-if="tipoIntegracao != 'ADMH'"
              class="ma-2"
              data-cy="dadosp-sobrenome"
              label="Sobrenome"
              append-icon="mdi-text"
              style="width: 200px; padding-top: 5px"
              v-model="sobrenome"
              :rules="surnameRules"
              @keyup="[validate(), surnameToUpper()]"
              @blur="validate"
              required
            />

            <v-text-field v-if="tipoIntegracao == 'ADMH'"
              class="ma-2"
              data-cy="dadosp-sobrenome"
              label="Sobrenome"
              append-icon="mdi-text"
              style="width: 200px; padding-top: 5px"
              v-model="sobrenome"
              @keyup="[ surnameToUpper()]"
            />
            
          </v-row>

          <v-row style="display: flex; padding-top: 5px" class="pa-2">
            <v-text-field
              class="ma-2"
              type="date"
              label="Nascimento"
              style="width: 40px; padding-top: 5px"
              v-model="datanascimento"
              data-cy="dadosp-nascimento"
              @blur="[validate(), idade()]"
              @keyup="[validate(), idade()]"
              :rules="datanascimentoRules"
              required
            />
            <span class="pr-4">{{ anosIdade }} ano(s)</span>
            
              <v-select
                class="ma-2"
                append-icon="mdi-account-details"
                :items="documentos"
                item-value="id"
                item-text="descricao"
                label="Tipo de Documento"
                style="width: 50px; padding-top: 5px"
                v-model="tipodocumento"
                :rules="parseInt(anosIdade)>=18?selectsRules:[true]"
                @change="validate"
                @blur="validate"
                id="dadosp-tipodoc"
                data-cy="dadosp-tipodoc"
                required
              ></v-select>
            
            
            <v-text-field
              id="textoNumeroDocumento"
              class="ma-2"
              label="Nº Documento"
              append-icon="mdi-format-list-numbered"
              style="width: 80px; padding-top: 5px"
              data-cy="dadosp-numdoc"
              v-model="numerodocumento" 
              :rules="tipodocumento==idTipoDocCpf&&parseInt(anosIdade)>=18?numerodocumentoRules:[true]"
              @blur="validate"
              @keyup="validate"
              required
            />
            
              <v-select
                append-icon="mdi-map-marker"
                label="Nacionalidade"
                v-model="nacionalidade"
                :items="nacionalidades"
                item-text="nacionalidade"
                item-value="id"
                :rules="selectsRules"
                data-cy="dadosp-nacionalidade"
                id="dadosp-nacionalidade"
                required>
              </v-select>
            

          </v-row>
          <v-row style="display: flex-box !important; padding-top: 5px">
            
              <v-select
                class="ma-2"
                :items="['M', 'F']"
                append-icon="mdi-gender-male-female"
                label="Sexo/Gênero"
                style="width: 10px; padding-top: 5px"
                v-model="sexo"
                item-value="M"
                :rules="selectsRules"
                @blur="validate"
                @change="validate"
                data-cy="dadosp-genero"
                id="dadosp-genero"
                required
              >
              </v-select>
            
            
              <v-select
              class="ma-2"
              label="Ocupação / Profissão"
              style="width: 100px; padding-top: 5px"
              :items="profissoes"
              item-text="descricao"
              item-value="id"
              :rules="selectsRules"
              @change="[validate()]"
              data-cy="dadosp-ocupacao"
              id="dadosp-ocupacao"
              v-model="profissao"
            >
            </v-select>
            

            <v-text-field
              class="ma-2"
              type="email"
              label="E-mail"
              append-icon="mdi-email"
              data-cy="dadosp-email"
              style="width: 100px; padding-top: 5px"
              v-model="email"
              :rules="emailRules"
              @keyup="[validate(), emailToLower()]"
              @blur="validate"
              required
            />
          </v-row>
          <v-row v-if="nomehotel.toUpperCase().trim() == 'ESMERALDA PRAIA HOTEL'" style="display: flex-box !important; padding-top: 5px">
                <v-select
                  class="ma-2"
                  label="Enviar Web Checkout"
                  style="width: 100px; padding-top: 5px"
                  :items="enviowebcheckoutlist"
                  item-text="descricao"
                  item-value="id"
                  data-cy="dadosp-ocupacao"
                  id="dadosp-ocupacao"
                  v-model="flenviowebcheckout"
                >
                </v-select>
          </v-row>
          <div style="text-align: center">
            <v-spacer></v-spacer>

            <v-btn style="color:white;" v-if="btnSalvarHabilidado&&possuiEmpresaReservante"
              color="light-green"
              medium
               class="mb-4 mt-4"
              @click="salvarPasso()"
              :disabled="!valid||salvarBloqueado"
              data-cy="dadosp-btnsalvar"
              >Salvar</v-btn
            >
            <v-btn style="color:white;font-weight:bold" v-else
              color="light-green"
              medium
              class="mb-4 mt-4"
              :disabled="true"
              >{{possuiEmpresaReservante?'FICHA ESTÁ FINALIZADA':'FICHA BLOQUEADA'}}</v-btn
            >
            <div v-if="importandoCPF"><span>{{importandoCPF}}</span></div>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
              <span>{{ snackText }}</span>
              <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>        
      </v-form>
      <div v-if="isBirthDay">Aniversário aqui</div>



      <v-dialog
        v-model="exibirMerge"
        width="50%"
        transition="dialog-top-transition"
        style="background: white"
      >
        <v-card style="height: 550px !important;" height="100%">

          <div >
            <Merge 
              @atualizarDadosMerge="(dados) =>atualizarDadosMerge(dados)"
              v-bind:dadosMerge="dadosMerge"
              v-bind:exibirMerge="exibirMerge" />
          </div>

          
          <v-footer absolute style="margini-left: auto !important; magini-right: auto !important; width:100%;">
              <v-spacer />
              
                <v-btn class="ml-2" @click="exibirMerge = false" color="red" 
                  style="color:white;">Sair<v-icon small>mdi-logout</v-icon>
                </v-btn>
              <v-spacer />
            
            </v-footer>
        </v-card>
      </v-dialog>

    </v-card>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="confirmarSubstituicao"
          persistent
          max-width="600"
        >
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Open Dialog
            </v-btn>
          </template> -->
          <v-card>
            <v-card-title class="text-h5">
              Já existe uma pessoa com o mesmo CPF:
            </v-card-title>
            <v-card-text>
              <strong>{{this.dadosOutraPessoa.nome+' '+this.dadosOutraPessoa.sobrenome}}</strong> <br>
              <strong>Deseja utilizar os seus dados?</strong>
              <br> Caso negativo, informar outro CPF.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="naoSubstituir()"
                data-cy="btn-dadospnaosub"
              >
                Não
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="substituirPessoa()" 
                data-cy="btn-dadospsub"
              >
                Sim
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <copiar-dados :show="exibirCopiarDados" :items="hospedesReserva" :idhospede="idhospede"
      :docfrente="docfrente"
      :docverso="docverso"
      :idhospedepms="idhospedepms" :numeroreserva="numeroreserva" :nomehospede="nome" 
      :idreserva="idreserva"
      @close="fecharCopiaDados" 
      @search="(res) => buscarHospedes(res)"
      @copy="(dados) => copiarDadosHospede(dados)"
    ></copiar-dados>
  </div>
</template>
<script>
import { cpf } from 'cpf-cnpj-validator'; 
import Merge from './Merge.vue';
import CopiarDados from './CopiarDados.vue';
import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
  props: ["id","idreserva", "numeroreserva", "idhospede", "possuiEmpresaReservante"],
  name: "DadosPessoais",
  components: {
      Merge,
      CopiarDados,
      BotaoTutorial
  },
  data: () => ({
    tipoIntegracao:'HITS',
    idTipoDocCpf:null,
    idreservapms:null,
    idhospedepms:null,
    confirmarSubstituicao: false,
    substituir: false,
    principal:null,
    dadosOutraPessoa: false,
    dadosMergeEncontrado:false,
    dadosMerge:{
      // nomeAtual:'Herl',
      // nomeNovo:'Herlessi',
      // sobrenomeAtual:'Noguis',
      // sobrenomeNovo:'Nogueira',
      // telefoneAtual:'8598',
      // telefoneNovo:'85982154043',
    },
    
    exibirMerge:false,
    nome: "",
    sobrenome: "",
    tipodocumento: "",
    numerodocumento: "",
    datanascimento: "",
    ddd:null,
    telefone:null,
    sexo: "",
    profissao: "",
    email: "",
    nacionalidades : [],
    nacionalidade : "",
    ano_aniversario: 0,
    mes_aniversario: 0,
    dia_aniversario: 0,
    isBirthDay: false,
    datacheckinprevisto: "",
    datacheckoutprevisto: "",
    profissoes: [],
    documentos: [],
    enviowebcheckoutlist: [
      {id:1,descricao:"Enviar Web Checkout"},
      {id:2,descricao:"Não Enviar Web Checkout"}
    ],
    flenviowebcheckout:2,
    nomehotel:null,
    btn: false,
    valid: true,
    nameRules: [
      (v) => !!v || "Campo Obrigatório",
      (v) => (v && v.length <= 150) || "Campo deve ser igual ou menor que 150 caracteres",
    ],
    surnameRules: [
      (v) => !!v || "Campo Obrigatório",
      (v) => (v && v.length <= 150) || "Campo deve ser igual ou menor que 150 caracteres",
    ],
    numerodocumentoRules: [
      (v) => !!v || "Campo Obrigatório",
      (v) => (v && v.length <= 20) || "Campo deve ser igual ou menor que 20 caracteres",
      (v) => ((cpf.isValid(v)) && (v.replace(/[^a-z0-9]/gi,'').length == 11) || "CPF inválido"),
    ],
    datanascimentoRules: [
      (v) => !!v || "Campo Obrigatório",
      (v) => (v && v.length <= 150) || "Campo deve ser igual ou menor que 150 caracteres",
    ],
    selectsRules: [(v) => !!v || "Campo Obrigatório"],
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    select: null,
    snack: false,
    snackColor: "",
    snackText: "",
    ano_atual: 0,
    mes_atual: 0,
    dia_atual: 0,
    quantos_anos: 0,
    anosIdade: 0,
    sucesso: false,
    btnSalvarHabilidado:true,
    exibirCopiarDados: false,
    hospedesReserva: [],
    docfrente:null,
    docverso:null,
    importandoCPF: null,
    salvarBloqueado: false
  }),
  computed: {
  },
  methods: {
    testarSocketContestacao(){
        this.$socket.disconnect();
            this.$socket.io.opts.query.usuario = localStorage.getItem('checkout-email')
            this.$socket.io.opts.query.tipo = 'WEBCHECKOUT'
            this.$socket.io.opts.query.tipo_acesso = 'WEBCHECKOUT_'+localStorage.getItem('checkout-email')
            this.$socket.connect();

            this.$socket.emit("wckout_to_back_recarregarDadosIniciais", {msg:'Sua solicitação de Estorno foi Negada!'});
    },
    exibirCopiar() {
      this.buscarHospedes(this.numeroreserva);
      this.exibirCopiarDados=true;
    },
    fecharCopiaDados() {
      this.exibirCopiarDados=false
      this.hospedesReserva=[]
    },
    buscarHospedes(numeroReserva) {
      if (numeroReserva) {
        var params = {
          hotel: localStorage.getItem("hotel"),
          numeroreserva: numeroReserva,
          idnot: this.id,
          preenchidos:true
        }
        console.log(JSON.stringify(params))
        this.$http.post("/reserva/select", params)
        .then((response) => {
          if (response.status == 200) {
            this.hospedesReserva = response.data
          }
        })
        .catch((e) => {
          console.error(e)
        })
      } else {
        this.hospedesReserva = []
      }
    },
    copiarDadosHospede(params) {
      console.log('copiarDadosHospede')
      console.log(params)
      this.$http.post("/reserva/upinsert", params)
        .then((response) => {
          if (response.status == 200) {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Dados copiados.";
            this.fecharCopiaDados();
            this.listarDados();
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(params));
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Não foi possível copiar os dados.";
        });
    },
    naoSubstituir() {
      this.substituir = false
      this.confirmarSubstituicao = false
      var textEl = document.getElementById('textoNumeroDocumento')
      if (textEl) {
        // TO DO: Essa parte não está funcionando...
        textEl.focus();
        textEl.select();
      } 
    },
    substituirPessoa() {
      this.substituir = true
      this.confirmarSubstituicao = false
      this.salvarBloqueado = false;
      this.importandoCPF = null;
      // Salvar os dados recarregando os dados do banco e sem sair do passo
      this.salvarPasso(true)
    },
    atualizarDadosMerge(dados){
      
      if(dados.nome){
        this.nome = dados.nome
      }

      if(dados.sobrenome){
        this.sobrenome = dados.sobrenome
      }

      if(dados.email){
        this.email = dados.email
      }
  
      if(dados.telefone && dados.telefone.length >= 10){
        this.ddd = dados.telefone.substring(0,2)
        this.telefone = dados.telefone.substring(2)
      }
      this.exibirMerge = false;
    },
    idade() {
      if(this.datanascimento.length < 1){
        return
      }
      var d = new Date();
      this.ano_atual = d.getFullYear();
      this.mes_atual = d.getMonth() + 1;
      this.dia_atual = d.getDate();

      this.ano_aniversario = this.datanascimento.substr(0, 4);
      this.mes_aniversario = this.datanascimento.substr(5, 2);
      this.dia_aniversario = this.datanascimento.substr(9, 2);

      this.quantos_anos = this.ano_atual - this.ano_aniversario;

      if (
        this.mes_atual < this.mes_aniversario ||
        (this.mes_atual == this.mes_aniversario &&
          this.dia_atual < this.dia_aniversario)
      ) {
        this.quantos_anos--;
      }

      this.anosIdade = this.quantos_anos < 0 ? 0 : this.quantos_anos || 0;

      if (
        d.getDate(this.datanascimento) <= d.getDate(this.datacheckinprevisto) &&
        d.getDate(this.datanascimento) >= d.getDate(this.datacheckoutprevisto)
      ) {
        // this.isBirthDay = true
      }
    },
    listarDados(salvarAoFinal=false) {
      this.$http
        .post("/reserva/select", {
          hotel: localStorage.getItem("hotel"),
          idhospede: this.idhospede,
          idreserva: this.idreserva,
          porid:1
        })
        .then(async (response) => {
          console.error(response.data)
          this.btnSalvarHabilidado = !response.data[0].enviadopms
          this.principal = response.data[0].principal
          this.nome = response.data[0].nome;
          this.sobrenome = response.data[0].sobrenome;
          this.tipodocumento = response.data[0].idtipodocumento
          this.numerodocumento = response.data[0].documento;
          if (response.data[0].nascimento != null) {
            let dataN = response.data[0].nascimento.substr(0, 10)
            this.datanascimento = this.moment(dataN).format("YYYY-MM-DD");
            this.idade()
          }
          this.sexo = response.data[0].sexo;
          this.profissao = response.data[0].idprofissao;
          this.email = response.data[0].email;
          this.datacheckinprevisto = response.data[0].datacheckinprevisto;
          this.datacheckoutprevisto = response.data[0].datacheckoutprevisto;
          this.idreservapms = response.data[0].idreservapms;
          this.idhospedepms = response.data[0].idhospedepms;
          if(response.data[0].idnacionalidade > 0){
            this.nacionalidade = await this.nacionalidades.find(nacionalidade => nacionalidade.id == response.data[0].idnacionalidade)
            this.nacionalidade = this.nacionalidade.id
          }

          if(response.data[0].dadosmerge > 0){
              this.$http
              .post("/reserva/reservatemp/select", {
                  numeroreserva: response.data[0].numeroreserva,
                  idhospedepms: response.data[0].idhospedepms
              }).then(resp =>{
                if(this.nome != resp.data[0].nome){ 
                  this.dadosMergeEncontrado = true
                  this.dadosMerge.nomeAtual = this.nome
                  this.dadosMerge.nomeNovo = resp.data[0].nome
                }

                if(this.sobrenome != resp.data[0].sobrenome){
                  this.dadosMergeEncontrado = true 
                  this.dadosMerge.sobrenomeAtual = this.sobrenome
                  this.dadosMerge.sobrenomeNovo = resp.data[0].sobrenome
                }

                 if(response.data[0].telefone != resp.data[0].telefone){ 
                  this.dadosMergeEncontrado = true
                  this.dadosMerge.telefoneAtual = response.data[0].telefone
                  this.dadosMerge.telefoneNovo = resp.data[0].telefone
                }

                if(this.email != resp.data[0].email){
                  this.dadosMergeEncontrado = true 
                  this.dadosMerge.emailAtual = this.email
                  this.dadosMerge.emailNovo = resp.data[0].email
                }

              }).catch(error=>{
                alert(error)
              })
          }

          this.docfrente = response.data[0].docfrente
          this.docverso  = response.data[0].docverso

          if(salvarAoFinal) {
            this.salvarPasso()
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async listarDocumentos() {
      await this.$http
        .post("/geo/documento/tipo/select")
        .then((response) => {
          this.documentos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async listarProfissoes() {
      await this.$http
        .post("/geo/profissao/select",{
          hotel: localStorage.getItem("hotel"),
          orderby: "descricao", 
          distinct: true
        })
        .then((response) => {
            var prof = response.data
                
            for(var i=0,len=prof.length;i<len;i++) {

              // if(i === 5){
              //   alert(prof[i].descricao)
              // }
              let profArray = prof[i].descricao.split(" ")
             
       
              
              if(profArray.length > 1) {
                
                 let profFinal = ''
                
                 
                  let primeirapalavra = profArray[0]
                  
                  // alert(primeirapalavra)
                  let ultimaLetra = primeirapalavra.trim().charAt(primeirapalavra.length-1)
                  // 
                  // if(i===7){
                  //   alert(primeirapalavra)
                  //   alert(ultimaLetra)
                  // }
                  if(ultimaLetra.toUpperCase() != 'A' && ultimaLetra.toUpperCase() != 'E' && ultimaLetra.toUpperCase() != 'R'){
                    prof[i].descricao = prof[i].descricao+' (A)'
                    for(var j=0,lenProf=profArray.length; j< lenProf; j++){
                      
                      if( j === 0 ) {
                        profFinal = profArray[j]+'(A) '
                      }else{
                        profFinal += profArray[j]
                      }
                    }
                  }else{
                    profFinal = prof[i].descricao
                  }
                
                prof[i].descricao = profFinal
              }else{
                let primeirapalavra = profArray[0]
                let ultimaLetra = primeirapalavra.trim().charAt(primeirapalavra.length-1)
                if(ultimaLetra.toUpperCase() != 'A' && ultimaLetra.toUpperCase() != 'E' && ultimaLetra.toUpperCase() != 'R'){
                  prof[i].descricao = prof[i].descricao+'(A)'
                }
              }
            }

            this.profissoes = prof;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async listarPaises(){
        await this.$http.post("geo/pais/select", {
          hotel: localStorage.getItem("hotel"),
          orderby: 'noaccent(nome)'})
        .then(resp =>{

                this.nacionalidades = resp.data

        })
    },
    salvarPasso(listarAoFinal=false) {
      let body = {
        reserva: {
          id: this.idreserva,
          hotel: localStorage.getItem("hotel"),
          datacheckinprevisto: this.datacheckinprevisto,
          iniciofd:true,
          idreservapms:this.idreservapms
        },
        hospede: {
          id: this.idhospede,
          idhospedepms: this.idhospedepms,
          nome: this.nome,
          sobrenome: this.sobrenome,
          nascimento: this.datanascimento,
          sexo: this.sexo,
          profissao: this.profissao,
          //cpf: this.numerodocumento,
          nacionalidade : this.nacionalidade,
          email : this.email,
          principal:this.principal,
          flenviowebcheckout:this.flenviowebcheckout,
          documentos: [
            {
              tipo: this.tipodocumento,   
              documento: this.numerodocumento?this.numerodocumento.replace(/[^a-z0-9]/gi,''):this.numerodocumento,
            },
          ],
          substituir: this.substituir
        },
      };
      if(this.ddd){ 
        body.hospede.telefoneddd = this.ddd
      }
      if(this.telefone){ 
        body.hospede.telefone = this.telefone 
      }
      body.useremail = localStorage.getItem('useremail');
      body.idhotelpms = localStorage.getItem('idhotelpms');
      //console.error(JSON.stringify(body));
      this.salvarBloqueado = true;
      this.$http
        .post("/reserva/upinsert", body)
        .then((response) => {          
          if (response.status == 200) {
            this.salvarBloqueado = false;
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Salvo com sucesso!";
            this.btn = false;
            this.reset();
            if (listarAoFinal) {
              this.listarDados();
            } else {
              //setTimeout(() => {
                this.$emit("avancar");
              //}, 1500);
            }
            this.$emit("update")
          } else {
            if (response.status == 206) {
              this.confirmarSubstituicao = true
              this.dadosOutraPessoa = response.data
              this.salvarBloqueado = false;
            } else {
              if (response.status == 204) {
                let roboAtivo = (localStorage.getItem('roboativo') == true) ||
                  (localStorage.getItem('roboativo') == 'true')
                //console.error('response.status:'+response.status)
                //console.error('robo ativo:'+roboAtivo)
                if (roboAtivo) {
                  this.importandoCPF = "Verificando se o documento existe no PMS...";
                  this.sockets.subscribe("backend_to_dash_importar_pessoa_fisica_retorno", (dados) => {
                    console.error(JSON.stringify(dados))
                    this.importandoCPF = null;
                    this.salvarBloqueado = false;
                    if (dados.id) {
                      this.confirmarSubstituicao = true
                      this.dadosOutraPessoa = dados
                    } else {
                        //this.salvarPasso();
                        //this.$emit("update");
                        this.$emit("avancar");
                    }
                  });
                } else {
                  this.importandoCPF = null;
                  this.salvarBloqueado = false;
                  this.$emit("avancar");
                }
              } else {
                console.log(JSON.stringify(response));
                this.salvarBloqueado = false;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Não foi possível salvar os dados. Verifique.";
        });
    },
    nameToUpper() {
      this.nome = this.nome.toUpperCase();
    },
    surnameToUpper() {
      this.sobrenome = this.sobrenome.toUpperCase();
    },
    emailToLower() {
      if (this.email) {
        this.email = this.email.toLowerCase();
      }
    },
    validate() {
      this.btn = this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    
  },
  async mounted() {
    this.tipoIntegracao = localStorage.getItem('TIPOINTEGRACAO')
    this.nomehotel = localStorage.getItem('nomehotel')
    if(localStorage.getItem('TIPOINTEGRACAO') == 'HITS'){
      this.idTipoDocCpf = 2
    }else{
      this.idTipoDocCpf = 1
    }

    await this.listarDocumentos();
    await this.listarPaises();
    await this.listarProfissoes();
    this.listarDados();
    this.validate();
    //this.idade()
  },
};
</script>

<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <div class="wout-topo">
            <div style="width:15%;">
                <button class="menu-voltar-btn" @click="voltar()">
                    <v-icon color="white">mdi-arrow-left</v-icon>
                </button>
            </div>
            <div class="white--text text-subtitle-2 px-2" style="width:70%;color:white!important;">
                <div v-if="dados.nome">{{dados.nome}}</div>
                <div class="wout-apto-reserva" style="color:white!important;" v-if="dados">Apto.{{dados.uh}} Reserva: {{dados.numeroreserva}}</div>
            </div>
            <div class="wout-power">
                <v-icon style="font-size: 28px!important;" color="white" @click="logout">mdi-power</v-icon>
            </div>
        </div>
        <!-- Conteúdo -->
        <div class="wout-conteudo">
            <!-- Resumo -->
            <br>
            <div class="cartao" >
                <div class="wout-row">
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-in:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckinprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-out:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckoutprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Taxa de Turismo -->
            <div v-if="cobrartaxaturismo" class="toggler">Taxa de Turismo da Cidade<v-icon>mdi-chevron-down</v-icon></div>
            <div v-if="cobrartaxaturismo" :class="taxaTur?'cartao-item-taxa taxa-ativada':'cartao-item-taxa taxa-cancelada'">
                <div class="wout-row">
                    <div style="width:33.67%;padding:0px;padding-left:0.5rem;">
                        <div class="taxa">Taxa {{pctaxaturismo}}%</div>
                    </div>
                    <div style="width:33.67%;text-align:right;padding:0px;padding-right:0.5rem;align-self:center;">
                        <span class="saldo-individual">R$ {{taxaTurismoValor | formatarMoeda }}</span>
                    </div>
                    <div class="item-btn" style="width:33.67%;">
                        <button class="contest-btn" @click="alternarTaxaTur()">
                            <span>{{taxaTur?'Cancelar taxa':'Incluir taxa'}}</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Taxa de colaboradores -->
            <div v-if="cobrartaxaservico" class="toggler">Taxa de colaboradores<v-icon>mdi-chevron-down</v-icon></div>
            <div v-if="cobrartaxaservico" :class="taxaCol?'cartao-item-taxa taxa-ativada':'cartao-item-taxa taxa-cancelada'">
                <div class="wout-row">
                    <div style="width:33.67%;padding:0px;padding-left:0.5rem;">
                        <div class="taxa">Taxa {{pctaxaservico}}%</div>
                    </div>
                    <div style="width:33.67%;text-align:right;padding:0px;padding-right:0.5rem;align-self:center;">
                        <span class="saldo-individual">R$ {{taxaColaboradoresValor | formatarMoeda }}</span>
                    </div>
                    <div class="item-btn" style="width:33.67%;">
                        <button class="contest-btn" @click="alternarTaxaCol()">
                            <span>{{taxaCol?'Cancelar taxa':'Incluir taxa'}}</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Saldo -->
            <div class="wout-row-center">
                <div class="wout-campo-saldo-white">
                    <div class="wout-saldo">-R$ {{ valorAPagar | formatarMoeda}}</div>
                    <div class="wout-label-saldo"><b>Saldo em aberto</b></div>
                </div>
            </div>            
            <!-- Pagar Total -->
            <div style="display:flex;justify-content:center;padding-top:2vh;">
                <button class="pagar-btn"
                    v-on:click="pagar()"
                    id="start_button"
                >
                    <span class="sinal-moeda">$</span>
                    Efetuar Pagamento <span class="green--text">Total</span>
                    <v-icon>mdi-arrow-right</v-icon>
                </button>
            </div>
            <!-- Voltar -->
            <!-- <div style="display:flex;justify-content:center;padding-top:2vh;">
                <button class="voltar-btn"
                    v-on:click="voltar()"
                    id="start_button"
                >
                    <span class="voltar-icon">&nbsp;&nbsp;&nbsp;</span>
                    Voltar
                    <v-icon>mdi-arrow-left</v-icon>
                </button>
            </div> -->
        </div>
    </div>
</template>

<script>

export default {
    props:["dados"],
    data: () =>{
        return{
            dadosReserva:null,
            termolgpd:false,
            lgpd:false,
            exibirModalTermo:false,
            termoSrc:null,
            termoTipo:null,
            pagina:1,
            exibirDetalhes: true,
            taxaTur: true,
            taxaCol: true,
            cobrartaxaservico:null,
            pctaxaservico:null,
            cobrartaxaturismo:null,
            pctaxaturismo:null
        }
    },
    components: {
		
	},
    computed: {
        taxaTurismoPercentual() {
            var result
            try {
                result = this.dados.taxaturismo*100
            } catch (error) {
                result = 0.00
            }
            return result
        },
        taxaTurismoValor() {
            var result
            try {
                // if (this.taxaTur) {
                if (this.pctaxaturismo && this.cobrartaxaturismo) {
                    result = (parseFloat(this.dados.saldo)*(this.pctaxaturismo/100)).toFixed(2)
                } else {
                    result = 0.00
                }
            } catch (error) {
                result = 0.00
            }            
            return result
        },
        taxaColaboradoresPercentual() {
            var result
            try {
                result = this.dados.taxacolaboradores*100
            } catch (error) {
                result = 0.00
            }
            return result
        },
        taxaColaboradoresValor() {
            var result
            try {
                // if (this.taxaCol) {
                if (this.pctaxaservico && this.cobrartaxaservico) {
                    result = (parseFloat(this.dados.saldo)*(this.pctaxaservico/100)).toFixed(2)
                } else {
                    result = 0.00
                }
            } catch (error) {
                result = 0.00
            }            
            return result
        },
        valorAPagar () {
            return (
                parseFloat(this.dados.saldo) 
                + (this.taxaTur ? parseFloat(this.taxaTurismoValor) :0 )
                + (this.taxaCol ? parseFloat(this.taxaColaboradoresValor) :0)
            )
        }
    },
    methods: {
        alternarTaxaTur() {
            this.taxaTur = !this.taxaTur
        },
        alternarTaxaCol() {
            this.taxaCol = !this.taxaCol
        },
        logout(){
            this.$emit('direcionar', {to:'login', from:'prepagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio', from:'prepagamento'})
        },
        exibirConsumoIndividual(hospede) {
            this.$emit('direcionar', {to: 'individual', dados: hospede})
        },
        pagar() {
            this.dados.valorapagar = this.valorAPagar
            var taxas = []
            if (this.taxaTur) {
                taxas.push({
                    "CODPRODUTO":"TXTURISMO",
                    "PRECOUNITARIO":this.taxaTurismoValor,
                    "QUANTIDADE":"1",
                    "DESCPROD":"TAXA TURISMO",
                    "CODUH":"",
                    "IDLANCAMENTO":null
                })
            }
            if (this.taxaCol) {
                taxas.push({
                    "CODPRODUTO":"TXTCOLABORADORES",
                    "PRECOUNITARIO":this.taxaColaboradoresValor,
                    "QUANTIDADE":"1",
                    "DESCPROD":"TAXA COLABORADORES",
                    "CODUH":"",
                    "IDLANCAMENTO":null
                })
            }
            if (taxas.length>0) {
                this.dados.consumosextras = taxas
            }
            this.$emit('direcionar', {to:'pagamento', from:'prepagamento', dados: this.dados})
        }
    },
    async mounted(){
        let idhotel = await this.$route.params.token    

        this.$http.post("configuracao/buscarParametro", {  
                    "idhotel" : idhotel,
                    "chave" : 'TAXASCHECKOUT'
                }).then(resp =>{
                    if(resp.status == 200){
                        this.cobrartaxaservico  = resp.data[0].valor === 'true' ? true : false
                        this.pctaxaservico      = resp.data[0].valor2
                        this.cobrartaxaturismo  = resp.data[0].valor3 === 'true' ? true : false
                        this.pctaxaturismo      = resp.data[0].valor4
                    }else{
                        alert("Não foi possivel buscar os dados")
                    }
                })
                .catch(error =>{
                    console.log(error)
                })        
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
<template>
<div style="background-color: #1d4596; height: 100%;  background-image: url('/img/FichaLogo.png'); background-size: cover;">
  <v-main >
    <v-container  fluid >
      <v-layout align-center justify-center>
        <v-flex  md4>
          <v-card
          elevation="12"
            style="border-radius: 8px; max-width: 400px; background-color: #FFF;"
          >
            <v-toolbar
              style="background: #1d4596"
              height="120"
            >
              <v-toolbar-title style="color: #fff" align-center justify-center>
                <v-spacer></v-spacer>
                <img
                  src="/img/FichaLogo.png"
                  width="200"
                  height="120"
                  alt=""
                  srcset=""
                />
                <v-spacer></v-spacer>
              </v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field style="color: black;"
                  ref="useremail"
                  v-model="useremail"
                  :rules="[() => !!useremail || 'Este campo é obrigatório']"
                  prepend-icon="mdi-account"
                  label="Email / Login"
                  placeholder="Usuário ou email" 
                  required
                />
                <div class="ml-2">Enviaremos um email para recuperação de sua senha.</div>
              </v-form>
              <div class="mt-2">
                <a @click="modalajuda = true" class="m-2 pa-2">Ajuda</a>
              </div>
            </v-card-text>
            <v-divider class="mt-4" />
            <v-card-actions class="m-2 pa-2">
              <v-spacer />
              <v-btn
                align-center
                justify-center
                color="primary"
                @click="passwordRecovery"
                >OK
              </v-btn>
            </v-card-actions>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                  <span>{{ snackText }}</span>
                  <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-layout align-center justify-center>
        <v-flex md4>
<!--           <a href="#">
            <img src="/img/logo_p-1.png" width="150" height="50" />
          </a> -->
          <v-spacer/>
          <span class="font-weight-light copyright">
            &copy; 
            {{ new Date().getFullYear() }}
            <a href="https://economysoftware.com.br" target="_blank"
              >@economysoftware</a
            >
          </span>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
          <v-dialog v-model="modalajuda" width="50%" min-width="400px" style="min-height:90vh;">
              <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">
                  
                  <h1 class=" mb-5" >Fale com a Gente e Tire suas Dúvidas</h1>
                  <v-btn width="50%" class="info mb-5" >
                      <!-- <v-icon right dark class="mr-3">mdi-mail</v-icon> -->
                      {{dadoscontatoeconomy.valor2}}
                  </v-btn>
                  <v-btn width="50%" class="warning mb-5">
                      <v-icon right dark class="mr-3">mdi-phone</v-icon>
                      {{dadoscontatoeconomy.valor}}
                  </v-btn>
                  <v-btn width="50%" class="success mb-5" @click="direcionarWhatsapp()" >
                      <v-icon right dark class="mr-2">mdi-whatsapp</v-icon>
                      {{dadoscontatoeconomy.valor3}}
                  </v-btn>
                  
              </v-card>
          </v-dialog>

  </div>
</template>

<script>
export default {
  data: function () {
    return {
      useremail: "",
      snack: false,
      snackColor: 'success',
      snackText: '',
      color: "general",
      showPassword: false,
      dadoscontatoeconomy : null,
      modalajuda:false
    };
  },
  mounted(){

        this.$http.post("configuracao/buscarDadosContato")
                  .then(async resp =>{ 
                      // alert(JSON.stringify(resp.data[0])) 
                      this.dadoscontatoeconomy = resp.data[0]
                  }).catch(error =>{ alert(error);console.log(error) })
  },
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    direcionarWhatsapp(){
      window.open('https://wa.me/'+this.dadoscontatoeconomy.valor3+'?text=Ola,%20Preciso de uma ajudinha.','_blank')
    },
    passwordRecovery: function () {
      setTimeout(() => {}, 1000);
      if (this.useremail.length < 1) {
        this.snackText = "Informe seu usuário ou email cadastrado";
        this.snackColor = 'warning'
        this.snack = true;
        setTimeout(() => {
          this.snack = false;
        }, 3000);
        return;
      }
      let email = this.useremail;
      this.$store
        .dispatch("passwordRecovery", { email })
        .then(() =>{
          this.snackText = "Email enviado";
          this.snackColor = 'success';
          this.snack = true;
          setTimeout(() => { 
            this.snack = false;
            this.$router.push("/")
          }, 3000);
          
        })
        .catch((err) => {
          console.log(err);
          this.snackText = "Dados inválidos";
          this.snackColor = 'warning'
          this.snack = true;
          setTimeout(() => { 
            this.snack = false;
          }, 3000);
        });
    },
  },
  metaInfo() {
    return {
      title: "Ficha Digital | Recuperando sua senha",
    };
  },
};
</script>

<template>
    <v-dialog v-model="exibirModalEditarPassosWebCheckinPergunta" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Pergunta Extra WebCheckin
                    </span>
                    <span style="width:5%">
                        <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Pergunta Extra WebCheckin"/>
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalEditarPassosWebCheckinPergunta()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="exibirPerguntaExtraLocal" data-cy="exibirPerguntaExtraLocal" style="border-radius:5px;padding:5px;"></v-switch>
                        <label >Exibir Pergunta Extra?</label>  
                        
                    </div>


                    <div class="cardInputs" style="margin-top:10px;">
                        <label class="labels">Pergunta:</label>
                        <input type="text" class="inputs" v-model="pergunta" data-cy="pergunta" placeholder="Informe a pergunta extra" />
                    </div>

                    <div class="cardInputs">
                        <label class="labels">Possiveis respostas separadas por PONTO E VIRGULA ( ; )</label>
                        <v-textarea outlined name="input-7-4" style="border-radius:10px;" placeholder="SIM ; NÃO ; TALVEZ" v-model="resposta" data-cy="resposta"></v-textarea>
                    </div>

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvarPergunta()" data-cy="salvarPergunta" style="color:white;font-weight:bold;background-color:#2196f3;">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';



export default {
    data: () => ({
        pergunta:null,
        objetoPerguntaRetornado:null,
        respostas:[],
        resposta:'',
        exibirPerguntaExtraLocal:false,
        textobotaosalvar:"SALVAR"
    }),
    props:["exibirModalEditarPassosWebCheckinPergunta"],
    components:{
    BotaoTutorial
},
    methods: {
        buscarPerguntas(){
            this.$http.post("formulario/select",{chave:'PERGUNTAEXTRA'}).then(async resp =>{
                    this.objetoPerguntaRetornado = resp.data[0]
                    this.pergunta = resp.data[0].perguntas[0].texto
                    this.respostas = resp.data[0].perguntas[0].respostasaceitas.tipo.split(';')
                    // alert(JSON.stringify(resp.data[0].perguntas[0].respostasaceitas.tipo))
                    
                    this.respostas = await this.respostas.filter(p => {
                        return p != undefined;
                    }).sort((a, b) => (a > b) ? 1 : -1);

                    this.respostas = await this.respostas.map(p => {
                        p = p.toUpperCase();
                        this.resposta += p+' ; '
                        return p;
                    })

                    if(this.resposta[this.resposta.length - 2].trim() == ';'){
                        this.resposta = this.resposta.substr(0,this.resposta.length-3)
                    }

            })
        },
        salvarParametroPasso(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WCHECKINEXIBIRPERGUNTAEXTRA',
                    "valor" : this.exibirPerguntaExtraLocal 
                }).then(resp =>{
                    this.textobotaosalvar = "SALVAR"
                    this.setModalEditarPassosWebCheckinPergunta()
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
            
        },
        salvarPergunta(){
            this.textobotaosalvar = "SALVANDO..."
            let respostassplit = this.resposta.split(';')
            let objresposta = ''
            for(var i=0; i < respostassplit.length; i++ ){
                if(respostassplit[i].trim()){
                    objresposta += respostassplit[i].trim().toUpperCase()+";"
                }
            }

            if(objresposta[objresposta.length - 1].trim() == ';'){
                objresposta = objresposta.substr(0,objresposta.length-1)
            }

            try {
                    objresposta = objresposta.trim()

                    let body = {
                        id: this.objetoPerguntaRetornado.id,
                        chave: 'PERGUNTAEXTRA',
                        descricao: 'formulariodeperguntaextranowebcheckin',
                        ativo: true,
                        idioma: 'pt-BR',
                        pergunta:{
                            id:this.objetoPerguntaRetornado.perguntas[0].id,
                            texto:this.pergunta,
                            formulario:this.objetoPerguntaRetornado.id,
                            respostasaceitas:{"tipo":objresposta},
                            ativo:true,
                            idioma:'pt-BR'
                        }
                    }
        
                    // alert(JSON.stringify(body))salvarParametroPasso
                    this.$http.post("formulario/upinsert",body).then(async () =>{
                            // this.setModalEditarPassosWebCheckinPergunta()
                            this.salvarParametroPasso()
                    }).catch(error =>alert(error))
            } catch (error) {
                        console.log(error)
            }
        },
        setModalEditarPassosWebCheckinPergunta(){
            this.$emit('setModalEditarPassosWebCheckinPergunta')
        },
        // 

        
    },
    mounted() {

        this.buscarPerguntas()

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WCHECKINEXIBIRPERGUNTAEXTRA'
            }).then(resp =>{
                if(resp.status == 200){
                    // alert("11")
                    // alert(resp.data[0].valor)
                    this.exibirPerguntaExtraLocal = resp.data[0].valor === 'true' ? true : false
                    // alert("22")
                    // alert(this.exibirPerguntaExtraLocal)
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }
</style>
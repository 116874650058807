function buscarEnderecoViaCep(cep){
    return new Promise((resolve, reject) =>{

        try {
            
                cep = cep.replace(/\D/g, "");
                if (cep.length == 8) {

                    var validacep = /^[0-9]{8}$/;
        
                    if (validacep.test(cep)) {

                            var req = new XMLHttpRequest()
                            req.open('GET', `https://viacep.com.br/ws/${cep}/json/`)
                            var endereco = null

                            req.onreadystatechange = () => {

                                if (req.readyState === 4) {

                                    if (req.status === 200) {
                                        endereco = JSON.parse(req.response)
                                        endereco.pais = "Brasil"
                                        console.log("Endereço via CEP")
                                        console.log(endereco)
                                        resolve({status:200, dados:endereco}) 
                                    }else{
                                        resolve({status:400, msg:"CEP não Encotrado"}) 
                                    }
                                    
                                }
        
                            }

                            req.send()
        
                    } else {
                        console.log("error 1")
                        reject({status:400, msg:"CEP Inválido"}) 
                    }
        
                } else {
                    console.log("error 2")
                    reject({status:400, msg:"CEP com Tamanho Inválido"}) 
                }

        } catch (error) {
            reject({status:400, msg:error})
        }


    })
    
}


export {buscarEnderecoViaCep}
<template>
    <div class="py-4" style="flex-wrap:wrap;width:100%;">

        
        <h1 style="text-align:center;padding:1vh;margin:1vh;" >{{ $t('webcheckin.inicio.dadodasuareserva') }}</h1>

        <div class="resumoInicial" >
            <div style="margin-bottom:10px;">
                <div><b>{{ $t('webcheckin.inicio.numeroreserva') }}</b></div>
                <div>{{dados.numeroreserva}}</div>
            </div>
            <div style="margin-bottom:10px;">
                <div><b>{{ $t('webcheckin.inicio.nome') }}</b></div>
                <div v-if="dados.nome">{{dados.nome+' '+dados.sobrenome}}</div>
            </div>
            <div style="margin-bottom:10px;">
                <div><b>{{ $t('webcheckin.inicio.chegada') }}</b></div>
                <div>{{dados.datacheckinprevisto | formatarData}} às {{horacheckin}}</div>
            </div>
            <div style="margin-bottom:10px;">
                <div><b>{{ $t('webcheckin.inicio.saida') }}</b></div>
                <div>{{dados.datacheckoutprevisto | formatarData}} às {{horacheckout}}</div>
            </div>
            
        </div>

        <div class="cardInputs" 
            style="display:flex;flex-direction:row;justify-content:center;margin:10px;padding:10px;cursor:pointer;"
        >
            <input type="checkbox" id="endereco_compartilhado" name="endereco_compartilhado"
                data-cy="input-termos" 
                v-model="lgpd" true-value="true" false-value="false" 
                style="margin-left:10px;margin-right:0px;-ms-transform: scale(2);-webkit-transform: scale(2);-o-transform: scale(2);transform:scale(2);cursor:pointer;"
            >
            <div class="labels" style="margin-left:20px;padding-top:0px;cursor:pointer;">
                <label for="endereco_compartilhado" style="cursor:pointer;"
                >{{ $t('webcheckin.inicio.aceitacaotermo') }}</label>
            </div>
        </div> 

        <div class="cardInputs" style="display:flex;flex-direction:row;justify-content:center;padding-top:2px;padding-bottom:2px;">
            <a  target="blank_" style="text-decoration:underline!important;font-size:18px;" @click="exibirModalTermo = true" >
                {{ $t('webcheckin.inicio.termolgpd') }}
            </a>
        </div>

        <v-dialog v-model="exibirModalTermo" width="90%"  style="min-height:90vh;">
            <v-card style="min-height:90vh;">
                
            <v-toolbar color="blue" class="mb-8">
                    <v-flex  style="display: flex" class="pa-2">
                        <span style="width:95%;color:white;font-weight:bold;" >
                            Termo
                        </span>
                        <span style="width:5%;padding-right:30px;"> 
                            <v-btn x-small color="red" @click="exibirModalTermo = false" title="Fechar">
                                <v-icon x-small style="color:white;">mdi-close</v-icon>
                            </v-btn>
                        </span>
                    </v-flex>
                </v-toolbar>
    
                
                <div style="display:flex;align-items:center;flex-direction:column;">
                    <div style="margin-bottom:20px;"> 
                            <v-btn @click="()=>{ this.pagina > 1 ? this.pagina -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                            <span style="font-size:16px;">P&aacute;gina {{pagina}}</span>
                            <v-btn @click="()=>{ this.pagina += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                        
                    </div>
                    <pdf :src="termoSrc" style="width:80%!important;"
                        :page="pagina" >
                    </pdf>
                    <div style="margin-bottom:20px;margin-top:20px;"> 
                            <v-btn @click="()=>{ this.pagina > 1 ? this.pagina -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                            <span style="font-size:16px;">P&aacute;gina {{pagina}}</span>
                            <v-btn @click="()=>{ this.pagina += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                        
                    </div>
                </div>
                
                
            </v-card>
        </v-dialog>

        <div style="display:flex;justify-content:center;padding-top:0px;">
            <button
            class="botaoConfirmar"
            v-on:click="comecar()"
            data-cy="btn-comecar"
            
            id="start_button"
            >
            {{ $t('webcheckin.inicio.botaoComecar') }}
            </button>
        </div>

    </div>
</template>

<script>
import pdf from 'vue-pdf'
import dayjs from 'dayjs';
export default {
    props:["dados","hospedePrincipal"],
    data: () =>{
        return{
            dadosReserva:null,
            termolgpd:false,
            lgpd:false,
            exibirModalTermo:false,
            termoSrc:null,
            termoTipo:null,
            pagina:1,
            color: null,
            corPrincipal: null,
            corSecundaria: null,
            corFundo: null,
            horacheckin:null,
            horacheckout:null,
        }
    },
    components: {
		pdf: pdf
	},
    watch: {
        dados(val){
            this.dadosReserva = val
        },
        color: function(){
            alert(this.color)
            this.changeColor(this.color.hex)
        }
    },
    methods: {
        comecar(){
            let receberNotificaoValue = this.receber_notificacao == "true" ? true:false
            this.$socket.emit("zap_notificacao", {
                status: receberNotificaoValue
            });

            if(this.lgpd){
                var dadossalvar = this.dados
                dadossalvar.termolgpd  = this.lgpd
                dadossalvar.datainiciowc = dayjs().format()
                this.$emit('salvarDados', dadossalvar, 'doctipo')
                // this.$emit('salvarDados', dadossalvar, 'fotoselfie')
            }else{
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'O aceite dos Termos da LGPD é Obrigatorio!'})
            }
            
        },
        requisitarParametroHorario(){
            
            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'HORARIOPADRAOWEBCHECKIN'
            }).then(resp =>{
                
                if(resp.status == 200){
                 
                    this.horacheckin = dayjs(resp.data[0].valor).format("HH:mm")
                    this.horacheckout = dayjs(resp.data[0].valor2).format("HH:mm")
                    

                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                alert(error)
                console.log(error)
            })
        },
        changeColor(){
            let root = document.documentElement;
            root.style.setProperty('--webcheckin-principal', this.corPrincipal.hex);
            root.style.setProperty('--webcheckin-secundaria', this.corSecundaria.hex);
            root.style.setProperty('--webcheckin-fundo', this.corFundo.hex);
        },
        requisitarCores(){
            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WCHECKINESTILO'
            }).then(resp =>{
                if(resp.status == 200){
                    this.corPrincipal = JSON.parse(resp.data[0].valor)
                    this.corSecundaria = JSON.parse(resp.data[0].valor2)
                    this.corFundo = JSON.parse(resp.data[0].valor3)
                    this.changeColor()
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        }  
    },
    beforeMount(){
        this.requisitarCores()    
    },
    mounted(){
        // this.$i18n.locale = 'en'
        this.$http.post("perfil/hotel/select",{id:this.dados.hotel})
        .then(hotel => {

                this.$http.post("/imagem/select", {id:hotel.data[0].termolgpd}, { timeout: 60000 })
                .then((response) => {

                    const uint8Array = new Uint8Array(response.data[0].dados.data);
                    this.termoSrc = uint8Array.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');
                    this.termoTipo = response.data[0].tipo;

                })
                .catch((error) => {
                    console.log(error);
                });
        })

        this.requisitarParametroHorario()
    }
}
</script>


    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style >

    </style>
<template>

    <div style="width:100%;padding:10px;">


        <div style="width:100%;">
            <v-card style="">

                <v-toolbar color="light-blue" class="ma-2" style="color: white;display:flex;flex-direction:column;">
                    <div style="min-width:100%!important;display:flex;justify-content:space-between;">
                        <span class="toolbar-title" style="width:80%;">Controle de Acesso</span> 
                        <span style="width:5%;"> 
                            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Controle de Acesso"/>
                        </span>
                    </div>
                </v-toolbar>

              
                <v-card style="max-width:100%;padding-bottom:10px;" class="ma-2" >

                        <v-row style="" class="ma-2">
                        
                            <v-col cols="12" sm="12" md="4" lg="4" style="padding-bottom:0px;padding-top:0px;">
                                <v-select type="text" label="Status" v-model="statusreserva" :items="listadestatus"
                                    item-value="id" item-text="descricao" data-cy="filtro-status" return-object style="width:100%!important;padding-bottom:0px;" >
                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="12" md="4" lg="4" style="padding-bottom:0px;padding-top:0px;">
                                <v-text-field class="" type="text" label="Nome"  
                                    v-model="nome" data-cy="filtro-reserva" style="width:100%!important;padding-bottom:0px;" >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="4" lg="4" style="padding-bottom:0px;padding-top:0px;">
                                <v-text-field class="" type="text" label="Sobrenome"  
                                    v-model="sobrenome" data-cy="filtro-reserva" style="width:100%!important;" >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="4" lg="4" style="padding-bottom:0px;padding-top:0px;">
                                <v-text-field class="" type="text" label="UH"  
                                    v-model="uh" data-cy="filtro-reserva" style="width:100%!important;" >
                                </v-text-field>
                            </v-col>
            
                            
                        </v-row>

                        <div class="ma-2" style="display:flex;justify-content:flex-end;">

                            <div style="padding-bottom:0px;padding-top:10px;margin-right:20px;">
                                <v-btn @click="limparFiltro()" small color="warning" 
                                    data-cy="btn-limparfitro"
                                    style="color:white;min-height:40px;font-weight:bold;">
                                     <v-icon color="white">mdi-broom</v-icon> 
                                    Limpar Filtros
                                </v-btn>
                            </div>

                            <div style="padding-bottom:0px;padding-top:10px;">
                                 <v-btn @click="listarReservas()" small color="primary" 
                                    style="color:white;min-height:40px;font-weight:bold;"
                                    data-cy="btn-filtrar"
                                >
                                    <v-icon color="white" >mdi-magnify</v-icon> 
                                    {{textoBtnPequisar}}
                                </v-btn>
                            </div>

                        </div> 

                </v-card>
                
        
            
                <div v-for="reserva in listaReservas" :key="reserva.idhospede"  class="ma-2 pa-3"
                    style="border:1px solid black;border-radius:10px;" >
                    <div style="margin-bottom:10px;"><div style="font-weight:bold;">Nome</div>          <div style="font-weight:100;padding-left:10px;font-size:16px;">{{reserva.nome+ ' ' + reserva.sobrenome}}</div></div>
                    <div style="display:flex;"><div style="font-weight:bold;width:40%;">Dt Checkin</div>    <div style="font-weight:100;padding-left:10px;font-size:16px;">{{reserva.datacheckinprevisto | formatarData}}</div></div>
                    <div style="display:flex;"><div style="font-weight:bold;width:40%;">Dt Checkout</div>   <div style="font-weight:100;padding-left:10px;font-size:16px;">{{reserva.datacheckoutprevisto | formatarData}}</div></div>
                    <div style="display:flex;"><div style="font-weight:bold;width:40%;">UH</div>   <div style="font-weight:100;padding-left:10px;font-size:16px;">{{reserva.uh}}</div></div>
                    <div style="display:flex;"><div style="font-weight:bold;width:40%;">Ult. Dig. Doc.</div>   <div style="font-weight:100;padding-left:10px;font-size:16px;">{{reserva.documento ? reserva.documento.slice(reserva.documento.length-3):''}}</div></div>
                    <div class="ma-2" style="display:flex;justify-content:flex-end;">

                            <div style="padding-bottom:0px;padding-top:10px;margin-right:20px;">
                                <v-btn @click="()=>exibirDocModal(reserva)" small color="warning" 
                                    data-cy="btn-limparfitro"
                                    style="color:white;min-height:40px;font-weight:bold;">
                                     {{txtBtnBuscarDoc}}
                                </v-btn>
                            </div>

                            <div style="padding-bottom:0px;padding-top:10px;">
                                 <v-btn @click="()=>exibirSelfieModal(reserva)" small color="primary" 
                                    style="color:white;min-height:40px;font-weight:bold;"
                                    data-cy="btn-filtrar"
                                >
                                    {{txtBtnBuscarSelfie}}
                                </v-btn>
                            </div>

                    </div>
                </div>

                


            </v-card> 

        </div>


        <v-dialog v-model="exibirDoc">
            <v-card style="width: 90vw;height:50vh;display:flex;align-items:center;justify-content:center;">

                <img v-if="imgDocFrente" :src="'data:image.png;base64,' + imgDocFrente" class="imgUpload"
                 style="cursor: pointer;max-width:500px;" />
                 <div v-else style="width:100%;height:100%;display:flex;align-items:center;justify-content:center;">Aguarde...</div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="exibirSelfie">
            <v-card style="width: 90vw;height:50vh;">
                <img v-if="imgSelfie" :src="'data:image.png;base64,' + imgSelfie" class="imgUpload"
                 style="cursor: pointer;max-width:80vw;" />
                 <div v-else style="width:100%;height:100%;display:flex;align-items:center;justify-content:center;">Aguarde...</div>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import orto from '../../utils/ortografia'
import BotaoTutorial from '../material/BotaoTutorial.vue'

export default {
    name:"ControleAcesso",
    components: {
    BotaoTutorial
},
    data: () => ({
        nome:'',
        sobrenome:null,
        uh:null,
        textoBtnPequisar:'Buscar',
        listaReservas:[],
        listadestatus:[],
        statusreserva:{},
        exibirDoc:false,
        exibirSelfie:false,
        imgDocFrente:null,
        imgSelfie:null,
        txtBtnBuscarDoc:'Ver Documento',
        txtBtnBuscarSelfie:'Ver Selfie',
    }),

    computed: {

    },

    watch: {

    },
    
    created() {
        
    },
    async mounted(){
        this.statusreserva = JSON.parse(localStorage.getItem("ultimostatus"))
        await this.listarStatusReservas()

        if(!this.statusreserva){ 
            let dadosparametros = await this.$http.post("configuracao/buscarParametro", {idhotel: localStorage.getItem("hotel"),chave: 'RESERVASTATUSCHECKIN',}).then(resp => resp.data)
            let reservaEncontrada = this.listadestatus.find(x =>{
                return x.id == dadosparametros[0].valor
            })
            this.statusreserva = reservaEncontrada
        }
        // await this.listarReservas()
    },
    methods: {
        async exibirDocModal(reserva){
            if(reserva.docfrente){
                this.exibirDoc = true
                // this.txtBtnBuscarDoc = 'Buscando...'
                this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: reserva.docfrente,})
                    .then(async (responseimg) => {
                        if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                            this.imgDocFrente = await Buffer.from(responseimg.data[0].dados).toString()
                            
                            // this.txtBtnBuscarDoc = 'Ver Documento'
                        }
                    })
                    .catch((error) => { alert(error); console.log(error); });
            }else{
                alert("Reserva sem imagem de Documento")
            }
        },
        async exibirSelfieModal(reserva){
            if(reserva.selfie){
                this.exibirSelfie = true
                // this.txtBtnBuscarSelfie = 'Buscando...'
                this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: reserva.selfie,})
                    .then(async (responseimg) => {
                    if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                        this.imgSelfie = await Buffer.from(responseimg.data[0].dados).toString()
                        
                        // this.txtBtnBuscarSelfie = 'Ver Selfie'
                    }
                    })
                    .catch((error) => { alert(error); console.log(error); });
            }else{
                alert("Reserva sem imagem de Selfie")
            }
        },
        async listarStatusReservas() {
            await this.$http
                .post("/geo/statusreserva/select",{orderby: "descricao", distinct: true})
                .then((response) => {
                    this.listadestatus = response.data;
                    let statusreservapadrao = {id:-1,descricao:'Todos'}
                    this.listadestatus.push(statusreservapadrao)
                    this.listadestatus = orto.ordenarArray(this.listadestatus, "descricao")
                    this.filtroStatusReserva = statusreservapadrao
                })
                .catch((error) => {
                console.log(error);
                });
        },
        async listarReservas() {
            await this.$store.dispatch("mutate_ultimostatus", this.statusreserva)
            localStorage.setItem("ultimostatus",JSON.stringify(this.statusreserva))
            // get_ultimostatus
            this.textoBtnPequisar = "Buscando..."
            let params = {
                nome:this.nome,
                sobrenome:this.sobrenome,
                uh:this.uh,
                hospedado:true,
                idstatusreserva:this.statusreserva.id
            }
            // alert(JSON.stringify(params,null,2))

            await this.$http.post("/reserva/select",params)
                .then((response) => {
                    this.listaReservas = response.data;
                    this.textoBtnPequisar = "Buscar"
                })
                .catch((error) => {
                    console.log(error);
                    this.textoBtnPequisar = "Buscar"
                });
        
        }
    }

}
</script>

<style>
table.v-table thead tr {
  color: red !important;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.card-outter {
  position: relative;
  padding-bottom: 50px;
  width: auto;
  height: 700px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  align-items: center;
}
</style>

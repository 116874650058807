<template>
        
        <div class="" style="flex-wrap:wrap;width:100%;">
            <div class="cardInputs">
                <label class="labels" style="font-size:20px!important;">Qual a data do Check-In no Hotel?</label>
                <input type="text" v-mask="['##/##/####']" v-model="datacheckin"  class="inputs" placeholder="dd/mm/yyyy"  />
            </div>

            <div class="cardInputs">
                <label class="labels" style="font-size:20px!important;">Qual a sua data de Check-Out (Saida)?</label>
                <input type="text" v-mask="['##/##/####']" v-model="datacheckout"  class="inputs" placeholder="dd/mm/yyyy"  />
            </div>

            <div class="cardInputs">
                <label class="labels" style="font-size:20px!important;">Nome e Sobrenome</label>
                <input type="text" v-model="nome"  class="inputs" placeholder="Informe seu nome e Sobrenome"  />
            </div>

           


            <div class="cardInputs" style="display:flex;justify-content:center;align-items:center;">
                <button v-on:click="avancar()" class="botaoConfirmar">
                        Avançar
                </button>
            </div>

         
        </div>

       

</template>

<script>

// import moment from 'moment'
export default { 
    props:["dados"],
    data: () =>{
        return {
            // // os dados que salvarei na base do ficha
            datacheckin:'',
            datacheckout:'',
            nome:'',
            canal:null,
            

        }
    },
    computed: {
        // nacionalidades: function() {
        //     return this.paises.filter(p => {
        //         return p.nome != undefined;
        //     }).sort((a, b) => (a.nome > b.nome) ? 1 : -1);
        // }
    },
    methods:{
        
        validDate(data) {
            var re = /^([12][0-9])\/(0[1-9]|1[012])\/0[1-9]|[12][0-9]|3[01]{3}$/;
            return re.test(data);
        },
        validarFormulario(){

            if(!this.datacheckin){ 
                alert("Data de Checkin é Obrigatorio!")
                // this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Data de Checkin é Obrigatorio!'})
                return false
            }

            if(!this.datacheckout){ 
                alert("Data de Checkout é Obrigatorio!")
                // this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Data de Checkout é Obrigatorio!'})
                return false
            }

            if(!this.nome){ 
                alert("Nome é Obrigatorio!")
                // this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Nome é Obrigatorio!'})
                return false
            }

            if(!this.validDate(this.datacheckin)){ 
                alert("Data de Checkin Invalida!")
                // this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Data de Checkin Invalida!'})
                return false
            }

            if(!this.validDate(this.datacheckout)){ 
                alert("Data de Checkout Invalida!")
                // this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Data de Checkout Invalida!'})
                return false
            }

                return true
            

            
        },
        enviarDados(){

            let diaCkin = this.datacheckin.substring(0,2)
            let mesCkin = this.datacheckin.substring(3,5)
            let anoCkin = this.datacheckin.substring(6,10)
            let datacheckin = anoCkin+'-'+mesCkin+'-'+diaCkin

            let diaCkout = this.datacheckout.substring(0,2)
            let mesCkout = this.datacheckout.substring(3,5)
            let anoCkout = this.datacheckout.substring(6,10)
            let datacheckout = anoCkout+'-'+mesCkout+'-'+diaCkout
            
            var dadossalvar = this.dados
            dadossalvar.datacheckinprevisto     = datacheckin
            dadossalvar.datacheckoutprevisto    = datacheckout
            dadossalvar.nome                    = this.nome
           
            this.$emit('atualizarDados',dadossalvar,'Canais')

        },
        async avancar(){

            if( await this.validarFormulario() ){
                this.enviarDados()
            }  
            
        }
    },
    mounted(){
       
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
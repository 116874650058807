import { render, staticRenderFns } from "./Whatsapp.vue?vue&type=template&id=45de97f2&scoped=true&"
import script from "./Whatsapp.vue?vue&type=script&lang=js&"
export * from "./Whatsapp.vue?vue&type=script&lang=js&"
import style0 from "./Whatsapp.vue?vue&type=style&index=0&id=45de97f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45de97f2",
  null
  
)

export default component.exports
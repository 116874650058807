<template>
    <div class="wout-login" @click="avancar">
        <div class="center">
            <img src="@/assets/img/logowout.png" 
                width="100%" height="auto" alt="Web Checkout!" crossorigin />
        </div>
    </div>
</template>
<script>

export default {
    data: () => {
        return {
            
        }
    },
    mounted() {
        setTimeout(()=>{
            this.avancar()
        },3000)        
    },
    methods: {
        avancar() {
            this.$emit('direcionar', {from: 'introwout', to: 'prelogin'})
        }
    }
}
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 50vw;
}
</style>
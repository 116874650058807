function serializarImagem(e,percentualReducaoScale){
    return new Promise((resolve, reject) => {
        try {
            console.log('serializarImagem 1')
            
            // var files = e.target.files || e.dataTransfer.files;
            // var file = null
            // if (!files.length) {
            //     alert("Sem Imagem")
            // }
            var file = e
    
            // Faço a leitura do arquivo
            var reader = new FileReader()
            
            reader.onloadend = () => {
    
                var imagem = new Image()

                imagem.onload = function() {  
                        
                        let canvas = document.createElement("canvas");
                        canvas.width  = this.width * percentualReducaoScale
                        canvas.height = this.height * percentualReducaoScale
    
                        let contextoDoCanvas = canvas.getContext("2d");
                                           
                        contextoDoCanvas.scale(percentualReducaoScale, percentualReducaoScale);
                        contextoDoCanvas.drawImage(this, 0, 0)
    
                        var imagemescalada = canvas.toDataURL('image/jpeg', percentualReducaoScale);
                        
                        let tipoImagem = imagemescalada.split(",")[0]
                        tipoImagem = tipoImagem.split(";")[0]
                        tipoImagem = tipoImagem.split(":")[1]

                        let dados = imagemescalada.split(",")[1]
                        resolve({tipo:tipoImagem,dados:dados})
    
                } 

                imagem.src = reader.result
    
    
    
    
            }
    
    
            reader.readAsDataURL(file);
            
    
        } catch (error) {
            console.log(error)   
            reject(error)
        }

    })
    
}


// function serializarImagemNew(e,larguraNova){
function serializarImagemNew(e){
    return new Promise((resolve, reject) => {
        try {

            const DOISMEGAS = 2000000
            const QUATROMEGAS = 4000000
            var tamanhoArquivo = 0
            
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                alert("Sem Imagem")
            }
            var src = URL.createObjectURL(files[0]);
   
            let larguraNova = {}
            tamanhoArquivo = files[0].size

            if(tamanhoArquivo > QUATROMEGAS){
                larguraNova = {width: 600}
            }else{
                if(tamanhoArquivo > DOISMEGAS){
                    larguraNova = {width: 600}
                }else{
                    larguraNova = {width: 400}
                }
            }

            resizeImage(src, larguraNova).then(function (blob) {

                var reader = new FileReader()
                // document.querySelector("#resized").src = URL.createObjectURL(blob)
                reader.readAsDataURL(blob); 
                
                let percentualReducao = 0.8
                if(tamanhoArquivo > QUATROMEGAS){
                    percentualReducao = 0.7
                }else{
                    if(tamanhoArquivo > DOISMEGAS){
                        percentualReducao = 0.8
                    }else{
                        percentualReducao = 0.9
                    }
                }


                serializarImagem(blob,percentualReducao).then(resp =>{
                         resolve(resp)
                    })
                
                
  
            })
            
    
        } catch (error) {
            console.log(error)   
            reject(error)
        }

    })
    
}

function loadImage(img, src) {
    return new Promise((resolve, reject) => {
     
        img.src = src;  
        img.completed ? resolve(img) : img.addEventListener('load', function () {
            resolve(img)
        });

        img.addEventListener('error', reject);

    })
}

function resizeImage(src, options) {

    return loadImage(document.createElement('img'), src).then(function (image) {

        var canvas = document.createElement('canvas');

        if (options.width && !options.height) {
            options.height = image.height * (options.width / image.width)
        } else if (!options.width && options.height) {
            options.width = image.width * (options.height / image.height)
        }

        Object.assign(canvas, options);
        canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
        return new Promise(function (resolve) {
            canvas.toBlob(resolve, options.type || 'image/png', options.quality)
        })

    })

}

export  {

    serializarImagem,
    serializarImagemNew,
    resizeImage,
}
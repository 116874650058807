<template>
    <div class="captura_container" style="padding:0px;">
        <div class="captura_container_doc" style="padding:0px;margin:0px;width:100%;height:100%;">

            <div style="height:10%;margin-top:20px;">
                <h1>FOTO DA FRENTE - {{tipoDocSelecionado}}</h1>
            </div>

            <div style="height:60%;max-height:60%;">
                <template v-if="!imgSrcLocal ">
                    <p>modelo</p>
                    <img v-if="tipoDocSelecionado == 'RG'" class="imgExemplo" src="/img/leitorweb/rg_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'OUTROS'" class="imgExemplo" src="/img/leitorweb/rg_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'CNH'" class="imgExemplo" src="/img/leitorweb/cnh_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'PASSAPORTE'" class="imgExemplo" src="/img/leitorweb//passaporte_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'DNI'" class="imgExemplo" src="/img/leitorweb/dni_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'RNE'" class="imgExemplo" src="/img/leitorweb/rne_foto_frente.jpg" style="max-width:50vw;" >
                </template>
                <img v-if="imgSrcLocal" :src="'data:image.png;base64,' + imgSrcLocal" class="imgUpload" style="max-height:40vh;"/>
            </div>

            <div style="height:15%;max-height:height:15%;   ">

                <label  v-if="!image" for="file-upload" style="margin-top:10px;background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;cursor:pointer;">
                    <span style="font-size:18px;font-weight:bold;color:white;">{{btnMessage}}</span>
                </label>  
                <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="onFileChange" />

            </div>

            <div style="height:15%;max-height:15%;width:100%;">

                    <div style="display:flex;width:100%;padding:0px;margin:0px;width:100%;height:100%;justify-content:space-between;">

                        <button :disabled="lendoDoc" v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-enderecovoltar">
                            {{ $t('webcheckin.endereco.voltar') }}
                        </button>

                        <button  v-if="imgSrcLocal && !lendoDoc"  v-on:click="avancar()"  class="botaoConfirmar"  data-cy="btn-enderecoavancar" >{{ $t('webcheckin.endereco.avancar') }}
                        </button>

                    </div>

            </div>

            
            <!-- <template v-if="!imgSrcLocal">
                <p>modelo</p>
                <img v-if="tipoDocSelecionado == 'RG'" class="imgExemplo" src="/img/leitorweb/rg_foto_frente.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'OUTROS'" class="imgExemplo" src="/img/leitorweb/rg_foto_frente.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'CNH'" class="imgExemplo" src="/img/leitorweb/cnh_foto_frente.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'PASSAPORTE'" class="imgExemplo" src="/img/leitorweb//passaporte_foto_frente.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'DNI'" class="imgExemplo" src="/img/leitorweb/dni_foto_frente.jpg" style="max-width:50vw;" >
                <img v-if="tipoDocSelecionado == 'RNE'" class="imgExemplo" src="/img/leitorweb/rne_foto_frente.jpg" style="max-width:50vw;" >
            </template>
            <label  v-if="!image" for="file-upload" style="margin-top:10px;background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;cursor:pointer;">
                <span style="font-size:18px;font-weight:bold;color:white;">{{btnMessage}}</span>
            </label>    
            <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="onFileChange" />
        
            <img v-if="imgSrcLocal" :src="'data:image.png;base64,' + imgSrcLocal" class="imgUpload" style="max-height:50vh;"/>

            <button v-if="image" @click="removeImage" type="button" class="botaoRemover">Remover imagem</button> -->

        </div>


        <v-dialog v-model="lendoDoc" width="50%"  style="min-height:90vh;">
            <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">
                
                <h1>Aguarde!</h1>
                <div>Salvando seus dados...</div>
                
            </v-card>
        </v-dialog>
        
    </div>
</template>

<script>

import {serializarImagemNew} from '../../utils/serializar'

export default {
    name: 'DocFrente',
    data: () => ({
        image: '',
        imgSrcLocal:null,
        isImg:false,
        btnMessage:'Clique Aqui Para Enviar',
        lendoDoc: false
    }),
    props:["tipoDocSelecionado","idHospede","imgSrc",
        "idReserva","idReservaHospede", "docfrente"
        ],
    watch: {
        imgSrc(val) {
           this.imgSrcLocal = val
        },
    },
    methods: {
        voltar() {
            this.$emit('direcionar','doctipo')
        },
        avancar() {
            if (this.docfrente ){
                // this.$emit('direcionar','docverso')
                if(this.tipoDocSelecionado == "RG" || this.tipoDocSelecionado == "RNE" || this.tipoDocSelecionado == "OUTROS"){
                    this.$emit('direcionar','docverso')
                }else{
                    // se não for RG ou RNE não tem necessidade de capturar o verso do documento
                    this.$emit('direcionar','nacionalidade')
                }
            } else {
                alert('Envie a foto da frente para continuar')
            }
        },

        async manipularDirecionamento(idImagem){
           console.log(idImagem)
            // se for RG,CNH,PASSAPORTE ou RNE irá fazer leitura OCR da frente
            if(this.tipoDocSelecionado == "RG" || 
                this.tipoDocSelecionado == "CNH"  
                || this.tipoDocSelecionado == "PASSAPORTE"
                || this.tipoDocSelecionado == "RNE"
                ){
                    //solicitar leitura OCR aqui
                    /// -->
                    // try {
                    //         await this.$http.post('ocr/lerDocAuthSimples',{
                    //             token:'3c8478c895580214e0ff389448054854',
                    //             tipo_doc:this.tipoDocSelecionado,
                    //             lado:'FRENTE',
                    //             id:idImagem,
                    //             idHospede:this.idHospede,
                    //             idReserva:this.idReserva,
                    //             idReservaHospede:this.idReservaHospede,
                    //         })
                    //         .then(resp =>{
                    //             console.log(' ocr 1')
                    //             if(resp.status == 200){
                    //                 console.log("Leitura OCR REALIZADA")
                    //             }

                    //             console.log(' ocr 2')
                    //             // se for RG ou RNE irá solicitar captura do verso
                    //             if(this.tipoDocSelecionado == "RG" || this.tipoDocSelecionado == "RNE" || this.tipoDocSelecionado == "OUTROS"){
                    //                 this.$emit('direcionar','docverso')
                    //             }else{
                    //                 // se não for RG ou RNE não tem necessidade de capturar o verso do documento
                    //                 this.$emit('avancar')
                    //                 this.$emit('direcionar','nacionalidade')
                    //             }
                    //             this.btnMessage = 'Clique Aqui Para Enviar'
                    //             this.lendoDoc = false
                    //         })
                    //         .catch(error =>{
                    //             console.log(' ocr error 1',error)
                    //             this.btnMessage = 'Clique Aqui Para Enviar'
                    //             this.lendoDoc = false
                    //             this.$emit('direcionar','docverso')
                    //         })

                    // } catch (error) {
                    //      console.log(' ocr error 2',error)
                    //     this.$emit('direcionar','docverso')
                    // }

                    if(this.tipoDocSelecionado == "RG" || this.tipoDocSelecionado == "RNE" || this.tipoDocSelecionado == "OUTROS"){
                        this.$emit('direcionar','docverso')
                    }else{
                        // se não for RG ou RNE não tem necessidade de capturar o verso do documento
                        this.$emit('avancar')
                        this.$emit('direcionar','nacionalidade')
                    }
                    this.btnMessage = 'Clique Aqui Para Enviar'
                    this.lendoDoc = false

            }else{
                // se NÃO for RG,CNH,PASSAPORTE ou RNE então só redireciona para a captura do verso
                this.$emit('direcionar','docverso')
                this.btnMessage = 'Clique Aqui Para Enviar'
                this.lendoDoc = false
            }
            
        },
        async setImage(e) {
            const file = e.target.files[0];

            if (!file.type.includes("image/")) {
                alert("Please select an image file");
                return;
            }

            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.isImg = true;
                    this.imgSrcLocal = event.target.result;
                    let index = event.target.result.indexOf(",") + 1;
                    this.imgSrcLocal = event.target.result.substring(index,event.target.result.length);
                    if(this.imgSrcLocal){
                        this.salvaLogo(file.type)
                    }
                };
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        async salvaLogo(tipoImg) {
            try {
                
           

                    console.log('salvaLogo 1')
                    if (!this.imgSrcLocal.length > 0) {
                        console.log("invalido");
                        this.btnMessage = 'Clique Aqui Para Enviar'
                        this.lendoDoc = false
                        return;
                    }

                    console.log('salvaLogo 2')

                    let dados =   {
                        // "id": this.docfrente,
                        "token":'3c8478c895580214e0ff389448054854',
                        "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
                        "tipo" : tipoImg,
                        "nome" : "DocumentoFrente",
                        "dados" : this.imgSrcLocal,
                        "tabela": "hospede",
                        "fk": "docfrente",
                        "chave": "id",
                        "valor": this.idHospede
                    }
                    console.log('salvaLogo 3')

                    await this.$http.post("/imagem/upinsertAuthSimples",dados,
                        {timeout:100000 }
                        )
                        .then(async (response) => {

                            console.log('salvaLogo 4')
                            if(response.status == 200){
                                console.log("Documento Frente Salvo com Sucesso!");
                                this.$emit('update')
                                try {
                                    await this.manipularDirecionamento(this.docfrente?this.docfrente:response.data.result.imagem[0])
                                } catch (error) {
                                    this.$emit('direcionar','docverso')
                                }
                            }else{
                                console.log("Não foi possivel salvar Documento Frente!")
                                this.btnMessage = 'Clique Aqui Para Enviar'
                                this.lendoDoc = false
                            }
                        })
                        .catch((error) => {
                            console.log('salvaLogo error')
                            console.log(error.response);
                            this.btnMessage = 'Clique Aqui Para Enviar'
                            this.lendoDoc = false
                            this.$emit('direcionar','docverso')
                        });
            } catch (error) {
                console.log(error)    
            }
        },
        onFileChange(e) {

            this.btnMessage = 'Aguarde, Carregando...'
            this.lendoDoc = true
            // this.setImage(e)

            // serializarImagem(e, 0.7).then(resp =>{
            //     this.imgSrcLocal = resp.dados
            //     this.salvaLogo(resp.tipo);
            // }).catch(error =>{
            //     this.btnMessage = 'Clique Aqui Para Enviar'
            //     this.lendoDoc = false
            //     this.snack = true
            //     this.snackColor = "error" 
            //     this.snackText = error.message
            // })

            serializarImagemNew(e).then(async resp =>{
                this.imgSrcLocal = resp.dados
                await this.salvaLogo(resp.tipo);
                this.lendoDoc = false
            }).catch(error =>{
                this.btnMessage = 'Clique Aqui Para Enviar'
                this.lendoDoc = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
            
            
        },
        removeImage: function () {
            this.image = '';
        },
    },
    mounted() {
        this.imgSrcLocal = this.imgSrc
        this.$store.dispatch("setHiddenDrawer", true)
    },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
         height: 80vh; 
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_doc{
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
        margin-bottom: 1em;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>
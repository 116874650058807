<template>
    <v-dialog v-model="exibirModalFrigobarWebCheckout" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Checagem do Frigbar Pelo Mensageiro
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalFrigobarWebCheckout()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="checarpelomensageiro" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Frigobar deve ser checado pelo Mensageiro antes do Checkout?</label>    
                    </div>

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" color="primary">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>



export default {
    data: () => ({
        checarpelomensageiro:false,
        umdia:false,
        diariamente:false,
        cincodias:false,
        dezdias:false,
        trintadias:false,
        textobotaosalvar:"SALVAR",
    }),
    props:["exibirModalFrigobarWebCheckout"],
    components:{
        // ModalSolicitacoesResponder

    },
    methods: {
    
        salvar(){
            this.textobotaosalvar = "SALVANDO..."
            this.salvarENVIOAUTOWEBCHECKOUT().then(()=>{
                    this.setModalFrigobarWebCheckout()
            })
            
        },
      
        salvarENVIOAUTOWEBCHECKOUT(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WEBCKOUTCHECARFRIGOBAR',
                    "valor" : this.checarpelomensageiro ? 'true' : 'false'
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        setModalFrigobarWebCheckout(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setModalFrigobarWebCheckout')
        },
    },
    mounted() {

        this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #1560e5;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'WEBCKOUTCHECARFRIGOBAR'
            }).then(resp =>{
                if(resp.status == 200){
                    this.checarpelomensageiro      = resp.data[0].valor === 'true' ? true : false
                    this.umdia   = resp.data[0].valor2   === 'true' ? true : false
                    this.diariamente  = resp.data[0].valor3 === 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        
        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'ENVIOAUTOWEBCHECKIN2'
            }).then(resp =>{
                if(resp.status == 200){
                    this.cincodias      = resp.data[0].valor  === 'true' ? true : false
                    this.dezdias        = resp.data[0].valor2 === 'true' ? true : false
                    this.trintadias     = resp.data[0].valor3 === 'true' ? true : false
                    // alert(this.cincodias)
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })


    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }

  
  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    /* padding:5px 20px 5px 20px; */
    border: 1px solid #819ab4;
    width:100px; 
    margin-right: 20px;
    margin-top:10px;    
    
  }
</style>
/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import store from '../store'

export default [{
        path: '*',
        meta: {
            name: '',
            requiresAuth: true
        },
        redirect: {
            path: '/dashboard'
        }

    },
    // This  allows you to have pages apart of the app but no rendered inside the dash
    {
        path: '/',
        meta: {
            name: '',
            requiresAuth: false
        },
        component: () =>
            import ( /* webpackChunkName: "routes" */ `@/views/LoginHome.vue`),
        // redirect if already signed in
        beforeEnter: (to, from, next) => {
            if (store.getters.authorized) {
                next('/dashboard')
            } else {
                next()
            }
        },
        children: [{
                path: '',
                component: () =>
                    import (`@/components/LoginForm.vue`)
            },
            {
                path: '/esquecisenha',
                component: () =>
                    import (`@/components/ForgotPassword.vue`)
            },

        ]
    },
    {
        path: '/webcheckin/:token',
        component: () =>
            import (`@/components/WebCheckin.vue`)
    },
    {
        path: '/webcheckin/:token/:comp',
        component: () =>
            import (`@/components/WebCheckin.vue`)
    },
    {
        path: '/webcheckout/:token',
        component: () =>
        import (`@/components/WebCheckout.vue`)
    },
    {
        path: '/webcheckout',
        component: () =>
        import (`@/components/WebCheckout.vue`)
    },
    {
        path: '/webcheckinacesso/:tokenhotel',
        component: () =>
        import (`@/components/WebCheckinPortal.vue`)
    },
    {
        path: '/checkinpay/:token',
        component: () =>
            import (`@/components/CheckinPay.vue`)
    },
    {
        path: '/esapps',
        component: () =>
            import (`@/components/ESApps.vue`)
    },
    {
        path: '/appcaptura',
        component: () =>
            import (`@/views/AppCaptura.vue`)
    },
    {
        path: '/captureLogin/:idusuario/:modo',
        meta: {
            name: '',
            requiresAuth: false
        },
        component: () =>
            import (`@/views/CaptureLogin.vue`)
    },
    {
        path: '/listaopcoescaptura',
        meta: {
            name: '',
            requiresAuth: false
        },
        component: () =>
            import (`@/views/ListaOpcoesCaptura.vue`)
    },
    {
        path: '/capturar/:modo',
        name: 'Capturar Documentos',
        component: () =>
            import (`@/components/DashViews/CapturaDocAssinatura/CapturaDocumentos.vue`)
    },
    // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
    {
        path: '/resumoMensal',
        meta: {
            name: 'ResumoMensal',
            requiresAuth: false
        },

        component: () =>
            import (`@/components/DashViews/ResumoMensal.vue`)
    },
    {
        path: '/dashboard',
        meta: {
            name: 'Dashboard View',
            requiresAuth: true
        },
        component: () =>
            import (`@/views/DashboardView.vue`),
        children: [{
                path: '',
                name: 'Dashboard',
                component: () =>
                    import (`@/components/DashViews/Dashboard.vue`)
            },
            {
                path: 'user-profile',
                meta: {
                    name: 'User Profile',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/UserProfile.vue`)
            },
            {
                path: 'hotel-profile',
                meta: {
                    name: 'Hotel Profile',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/CadastroHotel.vue`)
            },
            /*       {
                    path: 'hotel-profile',
                    meta: {
                      name: 'Hotel Profile',
                      requiresAuth: true
                    },
                    component: () => import(`@/components/DashViews/CadastroHotel.vue`)
                  }, */
            {
                path: 'tutoriais',
                meta: {
                    name: 'Tutoriais FD',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Tutoriais.vue`)
            },
            {
                path: 'tutorial/:id',
                meta: {
                    name: 'Tutorial',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Tutorial.vue`)
            },
            {
                path: 'opcionais',
                meta: {
                    name: 'Opcionais FD',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Opcionais.vue`)
            },
            {
                path: 'gateways',
                meta: {
                    name: 'Gateways FD',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Gateways.vue`)
            },
            {
                path: 'gatewayscheckinpay',
                meta: {
                    name: 'Gateways CheckinPay',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/GatewaysCPay.vue`)
            },
            {
                path: 'table-list',
                meta: {
                    name: 'Table List',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/SimpleTables.vue`)
            },
            {
                path: 'user-tables',
                meta: {
                    name: 'User Table',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/UsersTable.vue`)
            },
            {
                path: 'tablestest',
                meta: {
                    name: 'Complex Tables test',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/TableList.vue`)
            },
            {
                path: 'icons',
                meta: {
                    name: 'Icons',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Icons.vue`)
            },
            {
                path: 'maps',
                meta: {
                    name: 'Maps',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Maps.vue`)
            },
            {
                path: 'notifications',
                meta: {
                    name: 'Notifications',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Notifications.vue`)
            },
            {
              path: 'mensagensNaoLidas',
              meta: {
                  name: 'MensagensWhatsappNaoLidas',
                  requiresAuth: true
              },
              component: () =>
                  import (`@/components/DashViews/MensagensWhatsappNaoLidas.vue`)
          },
            {
                path: 'captura-documento',
                meta: {
                    name: 'Capturar Documentos',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/CapturaDocAssinatura/CapturaDocumentos.vue`)
            },
            {
                path: 'captura-documento-teste',
                meta: {
                    name: 'Capturar Documentos Teste',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/CapturaDocAssinatura/CapturaDocumentoTeste.vue`)
            },
            {
                path: 'modal-solicitacoes-teste',
                meta: {
                    name: 'Modal Solicitacoes Teste',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/testes/TestarModalSolicitacoes.vue`)
            },
            {
                path: '/fichadigital',
                meta: {
                    name: 'Ficha Digital',
                    requiresAuth: true
                },

                component: () =>
                    import (`@/components/DashViews/FichaDigital.vue`)
            },
            {
                path: '/fichaexpress',
                meta: {
                    name: 'Ficha Express',
                    requiresAuth: true
                },

                component: () =>
                    import (`@/components/DashViews/FichaExpress.vue`)
            },
            {
                path: '/fichadigitala4/:tipo/:id',
                meta: {
                    name: 'fichaA4',
                    requiresAuth: true
                },

                component: () =>
                    import (`@/components/DashViews/FichaA4.vue`)
            },
            {
                path: '/pagamentos',
                meta: {
                    name: 'Pagamentos',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Pagamentos.vue`)
            },
            {
                path: '/whatsapp',
                meta: {
                    name: 'Whatsapp',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/DashViews/Whatsapp.vue`)
            },
            {
                path: '/pagamentos/checkinpay',
                meta: {
                    name: 'Pagamentos',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/checkinpay/PagamentosBaixas.vue`)
            },
            {
                path: '/pagamentos/vouchers',
                meta: { name: 'Vouchers',requiresAuth: true },
                component: () => import (`@/components/checkinpay/Vouchers.vue`)
            },
            {
                path: '/fd/webcheckout/configuracao',
                name: 'ConfiguracaoWebcheckout',
                component: () =>
                    import (`@/components/DashViews/ConfiguracaoWebcheckout.vue`)
            },
            {
                path: '/fd/webcheckout/contestacoes',
                name: 'Contestacoes',
                component: () =>
                    import (`@/components/DashViews/ContestacoesWebcheckout.vue`)
            },
            {
                path: '/fd/webcheckout/frigobar',
                name: 'Frigobar',
                component: () =>
                    import (`@/components/DashViews/ChecagemFrigobar.vue`)
            }
        ]
    },
    {
        path: '/fd',
        meta: {
            name: '',
            requiresAuth: false
        },
        component: () =>
            import (`@/views/DashboardView.vue`),
        children: [{
            path: 'configuracao',
            name: 'Configuracao',
            component: () =>
                import (`@/components/DashViews/Configuracao.vue`)
        }]

    },
    {
        path: '/faq',
        meta: {
            name: '',
            requiresAuth: false
        },
        component: () =>
            import (`@/views/DashboardView.vue`),
        children: [{
                path: 'duvidas-frequentes',
                name: 'Duvidas Frequentes',
                component: () =>
                    import (`@/components/DashViews/Faq.vue`)
            },
            {
                path: 'configuracao',
                name: 'FaqConfig',
                component: () =>
                    import (`@/components/DashViews/FaqConfig.vue`)
            }
        ]

    },
    {
        path: '/controle',
        meta: {
            name: '',
            requiresAuth: false
        },
        component: () =>
            import (`@/views/DashboardView.vue`),
        children: [{
                path: 'acesso',
                name: 'Acesso',
                component: () =>
                    import (`@/components/DashViews/ControleAcesso.vue`)
            }
        ]

    }
]